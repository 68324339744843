import React, { useEffect, useRef, useState, MouseEvent, ClipboardEvent, useCallback } from "react";
import { Modal, Button, Form, Row } from 'react-bootstrap';
import {
  IProjectEffortHours, IProjectEffortHoursByAction, IProjectEffortEstimation, IProjectEffortSummary,
  IProjectRolesResource, IProjectRoleUtilization, IAPIRequestFilter
} from '../../data-model'

import { useProjectDataContext } from "../../data-context/project-data-context";
import ConfirmationModal from "../../common/confirmation-dialog";
import { AddWeekInsideEfforts, deleteWeeklyEfforts, getAllWeeklyEffort, saveWeeklyEfforts } from "../_services/weekly-effort-svc";
import { calculatePercent, calculateWeeksDifference, delayStatement, formatDateAsString, formatToUSCurrency, getRGBColors, getRandomNumber, getWeekDateRangeByWeekNumber, isProjectReadOnlyStatus, isUserRoleReadOnly } from "../../utilities/helper-function";
import { toast } from 'react-toastify';
import LoadingIcon from '../../common/loading-icon/loading-icon';
import './project-effort-details.scss'
import { getProjectEffortHours, initialDefaultIProjectEffortEstimation, initialDefaultRoleResource } from "../_services/initial-default-data";
import {
  deleteProjectRoleResourceEstimation, deleteProjectRoleResourceEstimations, duplicateProjectRoleResourceEstimationsInfo, getProjectAllRoleResourceEstimation, getProjectRoleResourceEstimationByIds, saveProjectRoleResourceEstimation,
  updateProjectRoleResourceEstimation, updateProjectRoleResourceEstimationOrder, updateProjectRoleResourceEstimationsInfo
} from "../_services/role-resource-estimation-svc";
import { patchProjectData } from "../_services/dashboard-svc";
import { useDelayDebounce } from "../../common/hooks/debounce";
import IProjectPhaseUtilization from "../../data-model/project-phase-utilization";
import { CreateEffortReport } from "./effort-report";
import { PhaseEffort } from "../../common/class/phase-effort";
import { EffortCell, PhaseCell, SelectedCellSummary, UndoProperty } from "../../data-model/common-property";
import UseUnsavedChanges from "../../common/unsaved-form-warning/use-unsaved-changes";

const ProjectEffortDetails: React.FC<{ projectId?: number }> = ({ projectId }) => {
  const { currentProjectIdGblCtx, currentProjectInfoGblCtx, allProjectRoleResoursesGblCtx,
    projectEffortSummaryGblCtx, setProjectEffortSummaryGblCtx, refreshLevelOfEstimationGblCtx,
    setAllProjectRoleUtilizationGblCtx, refreshProjectStatusGblCtx, setCurrentProjectInfoGblCtx, setRefreshProjectHeaderGblCtx,
    setAllProjectPhaseUtilizationGblCtx, setIsRoleWillCreateAtEffortGblCtx,
    selectedPhaseGblCtx, setAllPhaseRoleUtilizationGblCtx, userAccessInfoGblCtx } = useProjectDataContext();

  const delayDebouncedValue = useDelayDebounce(2500);

  const [allInitialRoleResourseEstimation, setAllInitialRoleResourseEstimation] = useState<IProjectEffortEstimation[]>([]);
  const [allRoleResourseEstimation, setAllRoleResourseEstimation] = useState<IProjectEffortEstimation[]>([]);
  const [allInitialEffortHours, setAllInitialEffortHours] = useState<IProjectEffortHours[]>([]);
  const [allTempEffortHours, setAllTempEffortHours] = useState<IProjectEffortHours[]>([]);
  const [allFinalResourceEffort, setAllFinalResourceEffort] = useState<IProjectEffortHours[]>([]);
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
  const [showDuplicateEffortEstimationModal, setShowDuplicateEffortEstimationModal] = useState(false);
  const [showRoleResourceEstimationDeleteModal, setShowRoleResourceEstimationDeleteModal] = useState(false);
  const [maxWeekIndex, setMaxWeekIndex] = useState<number>(0);
  const [tempFixForSummaryUpdate, setTempFixForSummaryUpdate] = useState<number>(0);
  const [tempFixForPhaseCellUpdate, setTempFixForPhaseCellUpdate] = useState<number>(0);
  const [tempFixForUpdateSummaryAPI, setTempFixForUpdateSummaryAPI] = useState<number>(0);
  const [saveEffortDisabled, setSaveEffortDisabled] = useState<boolean>(true);
  const [resetEffortDisabled, setResetEffortDisabled] = useState<boolean>(true);
  const [addEffortDisabled, setAddEffortDisabled] = useState<boolean>(false);
  const [addRefreshDisabled, setAddRefreshDisabled] = useState<boolean>(false);
  const [deleteRoleResourceDisabled, setDeleteRoleResourceDisabled] = useState<boolean>(false);
  const [duplicateEstimationDisabled, setDuplicateEstimationDisabled] = useState<boolean>(true);
  const [deleteEstimationDisabled, setDeleteEstimationDisabled] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [weekToDeleteMessage, setWeekToDeleteMessage] = useState<string>('');
  const [roleResourceToDeleteMessage, setRoleResourceToDeleteMessage] = useState<string>('');
  const [loadingIconEffortGrid, setLoadingIconEffortGrid] = useState(false);
  const [isEffortHoursCellSelectionOn, setIsEffortHoursCellSelectionOn] = useState<boolean>(false);
  const [weekEffortColumn, setWeekEffortColumn] = useState<number[]>([]);
  const [deleteRoleDisabled, setDeleteRoleDisabled] = useState<boolean>(false);
  const [slectedRoleResourseEstimation, setSelectedRoleResourseEstimation] = useState<IProjectEffortEstimation | undefined>();
  const [updatedRoleResourseEstimations, setUpdatedRoleResourseEstimations] = useState<IProjectEffortEstimation[]>([]);
  const [selectedResourceEstimationRows, setSelectedResourceEstimationRows] = useState<IProjectEffortEstimation[]>([]);
  const [allPhaseSelectedCell, setAllPhaseSelectedCell] = useState<PhaseCell[]>([]);
  const [isPhaseCellSelectionOn, setIsPhaseCellSelectionOn] = useState<boolean>(false);
  const [selectedPhaseText, setSelectedPhaseText] = useState<string>('');
  const [saveEffortButtonColor, setSaveEffortButtonColor] = useState<string>('');
  const [isSaveEffortButtonVisible, setIsSaveEffortButtonVisible] = useState(true);
  const [blinkCountSaveEffortButtonMax, setBlinkCountSaveEffortButtonMax] = useState<number>(999999);
  const [loadingIconRole, setLoadingIconRole] = useState<boolean>(false);
  const [loadingIconRoleId, setLoadingIconRoleId] = useState<number>(0);
  const [disabledHideOrReadonly, setDisabledHideOrReadonly] = useState<boolean>(false);
  const [excludeBlankWeek, setExcludeBlankWeek] = useState<boolean>(false);
  const [disabledMoveUp, setDisabledMoveUp] = useState<boolean>(true);
  const [disabledMoveDown, setDisabledMoveDown] = useState<boolean>(true);
  const [tempFixForUpAndDownRefresh, setTempFixForUpAndDownRefresh] = useState<number>(0);
  const [autoSaveBlinkCount, setAutoSaveBlinkCount] = useState<number>(0);
  const [autoSaveText, setAutoSaveText] = useState<string>();
  const [autoSaveEnable, setAutoSaveEnable] = useState<boolean>(true);
  const [resourcePhaseColor, setResourcePhaseColor] = useState<boolean>(true);
  const [selectedCellSummary, setSelectedCellSummary] = useState<SelectedCellSummary | null>();
  const [displayMultiCellCutButton, setDisplayMultiCellCutButton] = useState<boolean>(false);
  const [displayMultiCellUndoButton, setDisplayMultiCellUndoButton] = useState<boolean>(false);

  const [clickedMultiCellCutButton, setClickedMultiCellCutButton] = useState<boolean>(false);
  const [displayMultiCellCopyButton, setDisplayMultiCellCopyButton] = useState<boolean>(false);
  const [clickedMultiCellCopyButton, setClickedMultiCellCopyButton] = useState<boolean>(false);
  const [displayMultiCellClearButton, setDisplayMultiCellClearButton] = useState<boolean>(false);
  const [displayMultiCellPasteButton, setDisplayMultiCellPasteButton] = useState<boolean>(false);
  const [allSelectedEffortCell, setAllSelectedEffortCell] = useState<EffortCell[]>([]);
  const [destinationEffortCell, setDestinationEffortCell] = useState<EffortCell | null>(null);
  const [allNewlyCopiedEffortCell, setAllNewlyCopiedEffortCell] = useState<EffortCell[]>([]);
  const [undoEffortCellSnaps, setUndoEffortCellSnaps] = useState<UndoProperty[]>([]);
  const [confirmCellCopyRefreshRandomNumber, setConfirmCellCopyRefreshRandomNumber] = useState<number>(-1);
  const [keyPressSnapCount, setKeyPressSnapCount] = useState<number>(0);
  const [controlEnterPressedRow, setControlEnterPressedRow] = useState<number | undefined | null>();
  const [effortCursor, setEffortCursor] = useState<string>('default');
  const [selectedWeekNumberColumn, setSelectedWeekNumberColumn] = useState<number>();
  const [performanceTuningOn, setPerformanceTuningOn] = useState<boolean>(true);

  const effortsTextBoxRefs = useRef<Array<HTMLInputElement | null>>([]);
  const phaseTextBoxRefs = useRef<Array<HTMLInputElement | null>>([]);
  const tableResourceEffortRef = useRef<HTMLDivElement>(null);

  const [visibleResourceEffortRange, setVisibleResourceEffortRange] = useState({
    minResourceRow: 0,
    maxResourceRow: 20,
    minEffortCol: 0,
    maxEffortCol: 30,
  });

  const cellWidth = 63;  // Cell width (in pixels)
  const cellHeight = 42; // Cell height (in pixels)

  const autoSaveDisplayNumber: number = 5;
  const autoSaveBlinkCountMax: number = 30;
  const totalKeyPressCountSnap: number = 20;
  const phaseColIndex: number = 1;
  const subPhaseColIndex: number = 2;
  const totalPhaseCol: number = 2;

  let saveButtonText = "Save All";
  let rowOrCloumnSelectedColor = '#ffff99';

  var phaseEffort = new PhaseEffort();
  let emptyRowDelete = false;

  useEffect(() => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {
      fetchAllRoleResourceEffortEstimations();
      fetchWeeklyEfforts();
    } else {
      setAllTempEffortHours([]);
      setAllInitialEffortHours([]);
      setAllRoleResourseEstimation([]);
      setMaxWeekIndex(0);
    }
  }, [projectId]);

  useEffect(() => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (refreshLevelOfEstimationGblCtx && refreshLevelOfEstimationGblCtx > 0 && projId != undefined && projId > 0) {
      fetchAllRoleResourceEffortEstimations();
      delayStatement(500).then(() => {
        setTempFixForUpdateSummaryAPI(getRandomNumber());
      });
    } else {
      setAllRoleResourseEstimation([]);
      setAllInitialEffortHours([]);
      setAllTempEffortHours([]);
      setMaxWeekIndex(0);
    }
    setAllSelectedEffortCell([]);
    setAllPhaseSelectedCell([]);
  }, [refreshLevelOfEstimationGblCtx]);

  useEffect(() => {
    let weeksColumn: number[] = Array.from({ length: maxWeekIndex }, (_, index) => index + 1);
    setWeekEffortColumn(weeksColumn);
  }, [maxWeekIndex]);

  useEffect(() => {
    if ((maxWeekIndex * allRoleResourseEstimation.length) > 1000) {
      setPerformanceTuningOn(true);
    } else {
      setPerformanceTuningOn(false);
    }
  }, [maxWeekIndex, allRoleResourseEstimation]);

  useEffect(() => {
    if (allSelectedEffortCell?.length > 1 && allSelectedEffortCell[0].LastEventName == 'clicked') {
      setSelectedCellSummary(calculatedCellSummary())
      setDisplayMultiCellCopyButton(true);
      setDisplayMultiCellCutButton(true);
      setDisplayMultiCellClearButton(true)
    } else {
      setSelectedCellSummary(null)
      setDisplayMultiCellCopyButton(false);
      setDisplayMultiCellCutButton(false);
      setDisplayMultiCellClearButton(false)
    }
    if (allSelectedEffortCell?.length >= 1) {
      if (allSelectedEffortCell[0]?.LastEventName == 'down') {
        setEffortCursor('grabbing');
      } else if (allSelectedEffortCell[0]?.LastEventName == 'clicked') {
        setEffortCursor('cell');
      }
    } else {
      setEffortCursor('default');
    }
    setClickedMultiCellCopyButton(false)
    setClickedMultiCellCutButton(false)
  }, [allSelectedEffortCell]);

  useEffect(() => {
    if (allSelectedEffortCell.length > 0 && (clickedMultiCellCopyButton || clickedMultiCellCutButton) && allSelectedEffortCell[0].LastEventName == 'clicked') {
      setDisplayMultiCellPasteButton(true)
    } else {
      setDisplayMultiCellPasteButton(false)
    }
  }, [clickedMultiCellCutButton, clickedMultiCellCopyButton]);

  useEffect(() => {//After Paste action
    if (confirmCellCopyRefreshRandomNumber > 0) {
      if (clickedMultiCellCutButton) {
        clearSelectedCellHoursByEmpty(allSelectedEffortCell);
      }
      fillCopiedEffortDataNewDestination(destinationEffortCell, allSelectedEffortCell)

      if (clickedMultiCellCutButton) {
        //clearSelectedCellHoursByEmpty(allSelectedEffortCell);
        setAllSelectedEffortCell([]);
        setDisplayMultiCellCutButton(false)
        setDisplayMultiCellCopyButton(false)
        setDisplayMultiCellClearButton(false)
        setDisplayMultiCellPasteButton(false)
        setClickedMultiCellCopyButton(false)
        setClickedMultiCellCutButton(false)
      }
      if (clickedMultiCellCopyButton) {
        setDisplayMultiCellPasteButton(true)
      }
      setDisplayMultiCellUndoButton(true)
      setDestinationEffortCell(null);
    }

  }, [confirmCellCopyRefreshRandomNumber]);

  useEffect(() => {
    setDisplayMultiCellUndoButton(undoEffortCellSnaps.length > 0)
  }, [undoEffortCellSnaps]);

  useEffect(() => {
    if (tempFixForSummaryUpdate > 0 && allRoleResourseEstimation.length >= 0 && allTempEffortHours.length >= 0) {
      var updatedRoleResources = setUpdatedProjectEffortSummary(allRoleResourseEstimation, allTempEffortHours);
      setAllRoleResourseEstimation(updatedRoleResources);
    }
  }, [tempFixForSummaryUpdate]);

  useEffect(() => {
    if (controlEnterPressedRow && controlEnterPressedRow > 0) {
      phaseTextBoxRefs.current[controlEnterPressedRow * 2 - 2]?.focus();
    }
  }, [controlEnterPressedRow]);

  useEffect(() => {
    setAllFinalResourceEffort([]);
    findAddedUpdatedDeletedEffortHours();
    setTempFixForSummaryUpdate(getRandomNumber());
  }, [allTempEffortHours]);

  useEffect(() => {
    if (allFinalResourceEffort.length > 0 || updatedRoleResourseEstimations.length > 0) {
      setSaveEffortDisabled(false);
      setResetEffortDisabled(false);
      setProjectPhaseUtilization();
    }
    else {
      setSaveEffortDisabled(true);
      setResetEffortDisabled(true);
    }
  }, [allFinalResourceEffort, updatedRoleResourseEstimations]);

  useEffect(() => {
    setRoleUtilization();
    setProjectPhaseUtilization();
  }, [allProjectRoleResoursesGblCtx, projectEffortSummaryGblCtx]);

  useEffect(() => {
    setDuplicateEstimationDisabled(!(selectedResourceEstimationRows.length > 0));
    setDeleteEstimationDisabled(!(selectedResourceEstimationRows.length > 0 || (selectedWeekNumberColumn && selectedWeekNumberColumn > 0)));
    setTempFixForUpAndDownRefresh(getRandomNumber());
  }, [selectedResourceEstimationRows]);

  useEffect(() => {
    if (allPhaseSelectedCell?.length > 0) {
      findUpdatedRoleResourceEstimationList();
    }
  }, [tempFixForPhaseCellUpdate]);

  useEffect(() => {
    setIsRoleWillCreateAtEffortGblCtx(saveEffortDisabled);
    const blinkInterval = setInterval(() => {
      setIsSaveEffortButtonVisible((prevVisibility) => !prevVisibility);
      setSaveEffortButtonColor('red');
      setAutoSaveBlinkCount(autoSaveBlinkCount + 1);
      if ((autoSaveBlinkCount == blinkCountSaveEffortButtonMax) || saveEffortDisabled) {
        clearInterval(blinkInterval);
        setIsSaveEffortButtonVisible(true);
        setSaveEffortButtonColor('');
        setAutoSaveBlinkCount(-1);
      }

    }, 1000);
    return () => {
      clearInterval(blinkInterval);
    };
  }, [saveEffortDisabled, autoSaveEnable, autoSaveBlinkCount]);


  useEffect(() => {
    let remainder: number = autoSaveBlinkCountMax - autoSaveBlinkCount;
    if (autoSaveEnable && !saveEffortDisabled && remainder <= autoSaveDisplayNumber) {
      if (remainder == 0) {
        setAutoSaveText(`Auto Saving...`);
        saveAllWeeklyEffortsEvent(true);
        setAutoSaveBlinkCount(-1);
      }
      else {
        if (remainder >= 1) {
          setAutoSaveText(`Saving in ${remainder}`);
        }
        else {
          setAutoSaveText(saveButtonText);
        }
      }
    } else {
      setAutoSaveText(saveButtonText);
    }
  }, [autoSaveBlinkCount]);

  useEffect(() => {
    if (tempFixForUpdateSummaryAPI > 0) {
      updateProjectHeaderData().then((res) => {
        if (res[0]) {
          toast.success(`Role/Resource Deleted Successfully`, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        } else {
          toast.error("Error occurred: " + res[1], { toastId: "error1", autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
      });
    }
  }, [tempFixForUpdateSummaryAPI]);

  useEffect(() => {
    if (refreshProjectStatusGblCtx && refreshProjectStatusGblCtx > 0) {
      if (isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles)) { //"Closed"
        setDisabledHideOrReadonly(true);
      } else {
        setMaxWeekIndex(calculateTotalWeekNumber(allInitialEffortHours));
        setDisabledHideOrReadonly(false);
      }
    }
  }, [refreshProjectStatusGblCtx]);

  useEffect(() => {
    setMaxWeekIndex(calculateTotalWeekNumber(allInitialEffortHours));
  }, [excludeBlankWeek]);

  useEffect(() => {
    if (tempFixForUpAndDownRefresh > 0) {
      refreshUPDownDisabled();
    }
  }, [tempFixForUpAndDownRefresh]);

  useEffect(() => {
    if (delayDebouncedValue > 0 && allProjectRoleResoursesGblCtx.length >= 0 && (allRoleResourseEstimation.length == 0 || allTempEffortHours.length == 0)) {
      hardRefreshEffortEstimations(allRoleResourseEstimation.length == 0, allTempEffortHours.length == 0);
      console.error('delay hard refresh called at page load');
    }
  }, [delayDebouncedValue]);

  useEffect(() => {
    setDisabledHideOrReadonly(loadingIconEffortGrid);
    if (isProjectReadOnlyStatus(currentProjectInfoGblCtx?.StatusId, currentProjectInfoGblCtx?.Status) || isUserRoleReadOnly(userAccessInfoGblCtx?.AssignedRoles)) { //"Closed"
      setDisabledHideOrReadonly(true);
    }
  }, [loadingIconEffortGrid]);

  useEffect(() => {
    if (projectEffortSummaryGblCtx && allProjectRoleResoursesGblCtx?.length > 0 && allRoleResourseEstimation?.length > 0) {
      let roleUtilization = phaseEffort.getRoleUtilizationByPhase(projectEffortSummaryGblCtx, allProjectRoleResoursesGblCtx, allRoleResourseEstimation, selectedPhaseGblCtx)
      setAllPhaseRoleUtilizationGblCtx(roleUtilization);
    }
  }, [selectedPhaseGblCtx]);

  const hardRefreshEffortEstimations = async (resourceEstimationLoad: boolean, effortLoad: boolean) => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId && projId > 0) {
      setAddRefreshDisabled(true);
      if (resourceEstimationLoad) {
        await fetchAllRoleResourceEffortEstimations();
      }
      if (effortLoad) {
        await fetchWeeklyEfforts();
      }
      setAddRefreshDisabled(false);
    }
  }

  const refreshUPDownDisabled = () => {
    if (selectedResourceEstimationRows.length == 1 && allRoleResourseEstimation.length > 1) {
      let allEstimationIds = [...new Set(selectedResourceEstimationRows.map(res => res.RoleResourceEstimateId))];
      let filteredRoleResEstimations = allRoleResourseEstimation.filter(resource => allEstimationIds.includes(resource.RoleResourceEstimateId));
      let index = Math.max(...filteredRoleResEstimations.map(role => allRoleResourseEstimation.indexOf(role)));
      if (index <= allRoleResourseEstimation.length) {
        setDisabledMoveDown(false)
      } else {
        setDisabledMoveDown(true)
      }
      if (index > 0) {
        setDisabledMoveUp(false)
      } else {
        setDisabledMoveUp(true)
      }
    } else {
      setDisabledMoveUp(true)
      setDisabledMoveDown(true)
    }
  }

  const updatePhaseAndTask = (estimations: IProjectEffortEstimation[]): IProjectEffortEstimation[] => {
    if (updatedRoleResourseEstimations.length > 0 && estimations.length > 0) {
      updatedRoleResourseEstimations.map((res) => {
        let est = estimations.filter((d) => d.RoleResourceEstimateId == res.RoleResourceEstimateId)[0];
        let matchedIndex = estimations.indexOf(est);
        est.Phase = res.Phase;
        est.SubPhase = res.SubPhase;
        estimations[matchedIndex] = est;
      })
    }
    return estimations;
  }

  const fetchAllRoleResourceEffortEstimations = async () => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId != undefined && projId > 0) {
      setLoadingIconEffortGrid(true);
      const data = await getProjectAllRoleResourceEstimation(projId);
      if (data) {
        let estimations = updatePhaseAndTask(data);
        setAllRoleResourseEstimation(estimations);
        setAllInitialRoleResourseEstimation(estimations);
        setTempFixForSummaryUpdate(getRandomNumber());
      }
    } else {
      setAllRoleResourseEstimation([]);
    }
    setLoadingIconEffortGrid(false);
  };

  const fetchWeeklyEfforts = async () => {
    setLoadingIconEffortGrid(true);
    projectId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    const data = await getAllWeeklyEffort(projectId);
    setLoadingIconEffortGrid(false);
    if (data) {
      setAllInitialEffortHours(data);
      setAllTempEffortHours(data);
      setMaxWeekIndex(calculateTotalWeekNumber(data));
    } else {
      setMaxWeekIndex(calculateTotalWeekNumber(null));
      setAllInitialEffortHours([]);
      setAllTempEffortHours([]);
    }
  }

  let calculateTotalWeekNumber = (effortsHours: IProjectEffortHours[] | null): number => {
    let allWeekNumbers = effortsHours?.map(effort => effort.WeekNumber);
    let maxWeek = Math.max(...allWeekNumbers ?? [], 0);

    if (!excludeBlankWeek) {
      let endDateMaxWeek: number = calculateWeeksDifference(currentProjectInfoGblCtx?.StartDate, currentProjectInfoGblCtx?.EndDate);
      if (maxWeek < endDateMaxWeek) {
        maxWeek = endDateMaxWeek;
        let [weekStartDate, weekEndDate] = getWeekDateRangeByWeekNumber((currentProjectInfoGblCtx?.StartDate ?? undefined), endDateMaxWeek);
        if (currentProjectInfoGblCtx?.StartDate && currentProjectInfoGblCtx?.EndDate && weekStartDate > currentProjectInfoGblCtx?.EndDate && weekEndDate > currentProjectInfoGblCtx?.EndDate) {
          maxWeek = maxWeek - 1;
        }
      }
    }
    if (maxWeekIndex > maxWeek) {
      maxWeek = maxWeekIndex;
    }
    return maxWeek;
  }

  const validateWeeklyEfforData = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
    const { name, value } = event.target;
    let isValid: boolean = false;
    let errorName: string | undefined = undefined;
    const errors: { [key: string]: string } = {};

    if (/^\d*\.?\d{0,2}$/.test(value) || value == '') {

      let hours = value == '' ? null : value.includes('.') ? parseFloat(value) : parseInt(value, 10);

      if (value == '' || value == undefined) {
        isValid = true;
      } else if (hours != null && (hours >= 0 && hours <= 55)) {
        isValid = true;
      }
      else {
        isValid = false;
        errorName = `Invalid effort entered. Hours of effort must be between 0 and 55. The number you entered was '${value}'.`
      }
    }

    if (!isValid) {
      toast.error(errorName, { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      errors[name] = errorName ?? '';
      setValidationErrors(errors);
    } else {
      setValidationErrors(errors);
    }

    return isValid;
  }

  const handleInputChange = async (event: any, roleresourceEstimation: IProjectEffortEstimation, rowIndex: number, colIndex: number) => {
    setAutoSaveBlinkCount(-1);
    const { name, value } = event.target;
    if (name.startsWith("Phase")) {
      roleresourceEstimation.Phase = value;
    }
    if (name.startsWith("Task")) {
      roleresourceEstimation.SubPhase = value;
    }
    setAllRoleResourseEstimation(prevRoles => {
      const updatedRoles = [...prevRoles];
      updatedRoles[rowIndex] = roleresourceEstimation;
      return updatedRoles;
    });

    addOrUpdatedToRoleResourceEstimationList(roleresourceEstimation);
  };

  const addOrUpdatedToRoleResourceEstimationList = (roleresourceEstimation: IProjectEffortEstimation) => {
    var matchedRes = updatedRoleResourseEstimations.filter(f => f.RoleResourceEstimateId == roleresourceEstimation.RoleResourceEstimateId);
    if (matchedRes?.length > 0) {
      setUpdatedRoleResourseEstimations(prevState => {
        return prevState.map(eachRes => {
          if (eachRes.RoleResourceEstimateId == roleresourceEstimation.RoleResourceEstimateId) {
            return roleresourceEstimation;
          }
          return eachRes;
        });
      });
    } else {
      setUpdatedRoleResourseEstimations((preRecords) => [...preRecords, roleresourceEstimation]);
    }
  }

  const handleAndSaveRoleResourceChange = async (event: any, index: number, roleresourceEstimation: IProjectEffortEstimation) => {
    setAutoSaveBlinkCount(-1);
    let oldResEstimate = { ...roleresourceEstimation };
    setLoadingIconRoleId(roleresourceEstimation.RoleResourceEstimateId);
    setLoadingIconRole(true);
    const { name, value } = event.target;
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx ?? 0;
    let selectedRes = allProjectRoleResoursesGblCtx.filter(item => item.RoleResourceId == value)[0];
    roleresourceEstimation.ProjectRoleResourceId = selectedRes.RoleResourceId;
    roleresourceEstimation.PrjectRoleResourceDetail = selectedRes;
    roleresourceEstimation.ProjectId = projId;

    if (roleresourceEstimation.RoleResourceEstimateId > 0) {//Update
      updateProjectRoleResourceEstimation(roleresourceEstimation).then(([status, message]) => {
        afterUpdateChangeRole(status, message, index, oldResEstimate, roleresourceEstimation);
      }).catch((error) => {
        setLoadingIconRoleId(0);
        setLoadingIconRole(false);
        toast.error("Error:" + error, { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
      });
    }
    else { //Add
      saveProjectRoleResourceEstimation(roleresourceEstimation).then(([status, message]) => {
        if (status) {
          let newlyCreatedRoleResourceEstimateId = parseInt(message);
          //Update all by resource id
          roleresourceEstimation.RoleResourceEstimateId = newlyCreatedRoleResourceEstimateId;

          let updatedEffortsList = allTempEffortHours.map(effortHour => {
            let newlyEffort = { ...effortHour };
            if (effortHour.RoleResourceEstimateId == oldResEstimate.RoleResourceEstimateId) {
              newlyEffort.RoleResourceEstimateId = newlyCreatedRoleResourceEstimateId
            }
            return newlyEffort;
          })
          setAllTempEffortHours(updatedEffortsList);

          let finalEffortsList = allFinalResourceEffort?.map(effortHour => {
            let newlyEffort = { ...effortHour };
            if (effortHour.RoleResourceEstimateId == oldResEstimate.RoleResourceEstimateId) {
              newlyEffort.RoleResourceEstimateId = newlyCreatedRoleResourceEstimateId
            }
            return newlyEffort;
          })
          setAllFinalResourceEffort(finalEffortsList);
        }

        afterUpdateChangeRole(status, message, index, oldResEstimate, roleresourceEstimation);
      }).catch((error) => {
        setLoadingIconRoleId(0);
        setLoadingIconRole(false);
        toast.error("Error:" + error, { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
      });
    }
  };

  const afterUpdateChangeRole = (changeStatus: boolean, message: string, index: number, oldRoleresourceEstimation: IProjectEffortEstimation, newRoleresourceEstimation: IProjectEffortEstimation) => {
    setLoadingIconRoleId(0);
    setLoadingIconRole(false);
    if (changeStatus) {//success
      setAllRoleResourseEstimation(prevRoles => {
        const updatedRoles = [...prevRoles];
        updatedRoles[index] = newRoleresourceEstimation;
        return updatedRoles;
      });
      setTempFixForSummaryUpdate(getRandomNumber());
      setSaveEffortDisabled(false);
    } else { //failed
      console.error("error occurred at afterUpdateChangeRole", message, oldRoleresourceEstimation)
      setAllRoleResourseEstimation(prevRoles => {
        const updatedRoles = [...prevRoles];
        updatedRoles[index] = oldRoleresourceEstimation;
        return updatedRoles;
      });
      toast.error("Error occurred", { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
    }
  }

  const handleWeekEffortChange = (event: any, roleResourceEstimateId: number, rowIndex: number, weekNumber: number, weekId: number) => {
    const { name, value } = event.target;
    setAutoSaveBlinkCount(-1);
    let isValidate = validateWeeklyEfforData(event);
    if (isValidate) {
      let hours = parseFloat(value);
      if (isNaN(parseFloat(value))) {
        hours = 0;
      }
      let effortHours = allTempEffortHours.filter(item => item.WeekNumber == weekNumber && item.RoleResourceEstimateId == roleResourceEstimateId)[0];
      if (effortHours != null) { //Edit
        setAllTempEffortHours(prevEffortHours =>
          prevEffortHours.map(effortHour =>
            effortHour.RoleResourceEstimateId == roleResourceEstimateId && effortHour.WeekNumber == weekNumber ? { ...effortHour, EffortHours: hours } : effortHour
          )
        );
      } else { //Add
        let newEffortHours = getProjectEffortHours(roleResourceEstimateId, weekId, weekNumber, hours);
        [newEffortHours.DateFrom, newEffortHours.DateTo] = getWeekDateRangeByWeekNumber(currentProjectInfoGblCtx?.StartDate ?? undefined, weekNumber);
        setAllTempEffortHours((prevEffortHours) => [...prevEffortHours, newEffortHours]);
      }
      setAllSelectedEffortCell([{
        RoleResourceEstimateId: roleResourceEstimateId,
        WeekNumber: weekNumber,
        RowIndex: rowIndex,
        //ColumnIndex: column,
        LastEventName: 'clicked'
      }]);
      createSnapData(allRoleResourseEstimation, allTempEffortHours, true);
      setTempFixForSummaryUpdate(getRandomNumber());
    }
  };

  const addWeekEffort = async (isClearSelectedCell: boolean) => {
    if (!addEffortDisabled) {
      if (maxWeekIndex < 156) {
        setLoadingIconEffortGrid(true);
        let [isSuccess, message] = [true, '']
        if (selectedWeekNumberColumn && selectedWeekNumberColumn > 0) { //Add week inside effort
          let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
          let [isSuccess, message] = await AddWeekInsideEfforts(projId ?? 0, (selectedWeekNumberColumn - 1));
          if (isSuccess) {
            await fetchWeeklyEfforts();
            setSelectedWeekNumberColumn(selectedWeekNumberColumn + 1);
          } else {
            return toast.error(`Error occurred: Unable to add week.`, { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
          }
        }
        if (isSuccess) {
          let newWeekNumber = maxWeekIndex + 1;
          let [weekStartDate, weekEndDate] = getWeekDateRangeByWeekNumber((currentProjectInfoGblCtx?.StartDate ?? undefined), newWeekNumber);

          if (currentProjectInfoGblCtx?.EndDate && weekEndDate > currentProjectInfoGblCtx?.EndDate) {
            toast.warning(`Please change project end date before add new week column. Your current end date is:'${formatDateAsString(currentProjectInfoGblCtx?.EndDate)}' and new end date will be: '${formatDateAsString(weekStartDate)}'`, { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
          }
          setMaxWeekIndex(newWeekNumber);
        }
        setLoadingIconEffortGrid(false);
      } else {
        return toast.error("Maximum 3 year(s) advanced plan allowed", { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
      if (isClearSelectedCell) {
        setAllSelectedEffortCell([]);
        setAllPhaseSelectedCell([]);
      }
    }
  };

  const createNewEstimationEffortRow = (index: number) => {
    let currentRosurceOrder = allRoleResourseEstimation[index]?.Order ?? 0;
    let prrEstimation = initialDefaultIProjectEffortEstimation();
    prrEstimation.RoleResourceEstimateId = -(getRandomNumber());
    prrEstimation.Order = currentRosurceOrder + 1;
    prrEstimation.ProjectId = currentProjectIdGblCtx ?? 0;

    const updatedRoleResourseEstimation = [...allRoleResourseEstimation];
    updatedRoleResourseEstimation.splice(index + 1, 0, prrEstimation);
    setAllRoleResourseEstimation(updatedRoleResourseEstimation);
  }

  const addRoleInsideEffort = (index: number,) => {
    if (selectedResourceEstimationRows.length > 0) {
      let allEstimationIds = [...new Set(selectedResourceEstimationRows.map(res => res.RoleResourceEstimateId))];
      let filteredRoleResEstimations = allRoleResourseEstimation.filter(resource => allEstimationIds.includes(resource.RoleResourceEstimateId));
      index = Math.max(...filteredRoleResEstimations.map(role => allRoleResourseEstimation.indexOf(role)));
    }
    createNewEstimationEffortRow(index);
  }

  const moveRoleUP = async (index?: number | undefined | null) => {
    if (!disabledMoveUp && selectedResourceEstimationRows.length == 1 && selectedResourceEstimationRows[0].RoleResourceEstimateId > 0) {
      index = allRoleResourseEstimation.indexOf(allRoleResourseEstimation.filter((res) => res.RoleResourceEstimateId == selectedResourceEstimationRows[0].RoleResourceEstimateId)[0]);
      if (index > 0 && allRoleResourseEstimation.length > 1) {
        setLoadingIconRole(true);
        setDisabledMoveUp(true);
        setLoadingIconRoleId(selectedResourceEstimationRows[0].RoleResourceEstimateId);
        let [isSuccess, message] = [false, ''];
        let currentRes = allRoleResourseEstimation[index];
        let upRes = allRoleResourseEstimation[index - 1];
        let cOrder = currentRes.Order;
        currentRes.Order = upRes.Order;
        upRes.Order = cOrder;
        var updatedRes = [currentRes, upRes];
        let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx ?? 0;
        [isSuccess, message] = await updateProjectRoleResourceEstimationOrder(projId, updatedRes);
        if (isSuccess) {
          const updatedList = [...allRoleResourseEstimation];
          [updatedList[index], updatedList[index - 1]] = [updatedList[index - 1], updatedList[index]];
          setAllRoleResourseEstimation(updatedList);
          setTempFixForUpAndDownRefresh(getRandomNumber());
        }
        else
          toast.error("Error occurred", { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
      }
      setDisabledMoveUp(false);
      setLoadingIconRole(false);
    }
  }

  const moveRoleDown = async (index?: number | undefined | null) => {
    if (!disabledMoveDown && selectedResourceEstimationRows.length == 1) {
      index = allRoleResourseEstimation.indexOf(allRoleResourseEstimation.filter((res) => res.RoleResourceEstimateId == selectedResourceEstimationRows[0].RoleResourceEstimateId)[0]);
      if (index < allRoleResourseEstimation.length - 1 && allRoleResourseEstimation.length > 1) {
        setLoadingIconRole(true);
        setDisabledMoveDown(true);
        setLoadingIconRoleId(selectedResourceEstimationRows[0].RoleResourceEstimateId);
        let [isSuccess, message] = [false, ''];
        let currentRes = allRoleResourseEstimation[index];
        let upRes = allRoleResourseEstimation[index + 1];
        let cOrder = currentRes.Order;
        currentRes.Order = upRes.Order;
        upRes.Order = cOrder;
        var updatedRes = [currentRes, upRes];
        let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx ?? 0;

        [isSuccess, message] = await updateProjectRoleResourceEstimationOrder(projId, updatedRes);
        if (isSuccess) {
          const updatedList = [...allRoleResourseEstimation];
          [updatedList[index], updatedList[index + 1]] = [updatedList[index + 1], updatedList[index]];
          setAllRoleResourseEstimation(updatedList);
          setTempFixForUpAndDownRefresh(getRandomNumber());
        }
        else
          toast.error("Error occurred", { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
      }
      setLoadingIconRole(false);
      setDisabledMoveDown(false);
    }
  }

  const saveWeeklyEffortsAll = async (isAutoSaveCalled?: boolean | false) => {
    if (currentProjectIdGblCtx && currentProjectIdGblCtx > 0) {
      let efforts = findAddedUpdatedDeletedEffortHours();
      if (efforts?.length > 0) {
        let [isSuccess, message] = await saveWeeklyEfforts(currentProjectIdGblCtx, efforts);
        if (isSuccess) {
          [isSuccess, message] = await updateProjectHeaderData();
        }
        if (isSuccess) {
          setAllFinalResourceEffort([]);
          if (efforts?.length == 0) {
            setSaveEffortDisabled(true);
            setResetEffortDisabled(true);
          }

          setAllInitialRoleResourseEstimation(allRoleResourseEstimation);
          fetchWeeklyEfforts();
          if (!isAutoSaveCalled) {
            toast.success("Saved Successfully.", { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
          }
        } else {
          if (!isAutoSaveCalled) {
            toast.error("Error:" + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
          }
        }
      }
    }
    setLoadingIconEffortGrid(false);
  };

  const updateProjectHeaderData = async (): Promise<[boolean, string]> => {

    let [isSuccess, message] = [false, ''];
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId && projId > 0) {
      let patchData: IAPIRequestFilter[] = [{
        Field: "estimatedservicesfeesaltrate",
        Operator: '',
        Value: currentProjectInfoGblCtx?.EstimatedServicesFeesAltRate?.toString() ?? '0',
        Text: ''
      },
      {
        Field: "estimatedservicesfeesstdrate",
        Operator: '',
        Value: currentProjectInfoGblCtx?.EstimatedServicesFeesSTDRate?.toString() ?? '0',
        Text: ''
      }
      ];
      [isSuccess, message] = await patchProjectData(projId, patchData)
      return [isSuccess, message];
    } else {
      toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
    return [isSuccess, message];
  }

  const updateProjectRoleResourceEstimations = async (resources: IProjectEffortEstimation[]) => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx ?? 0;
    let [isSuccess, message] = await updateProjectRoleResourceEstimationsInfo(projId, resources);
    if (isSuccess) {
      setUpdatedRoleResourseEstimations([]);
    } else {
      toast.error("Error: " + message, { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
  };

  const downloadEfforts = () => {
    if (allInitialRoleResourseEstimation.length > 0) {
      let effortHeaders: string[] = ["Phase", "Sub Phase", "Role", "Rate", "Total Hours", "Total Fees"];
      let effortHours: string[][] = [];
      weekEffortColumn.map((column) => {
        let [weekStartDate, weekEndDate] = getWeekDateRangeByWeekNumber(currentProjectInfoGblCtx?.StartDate ?? undefined, column);
        let startDateStr = weekStartDate.toISOString().substring(0, 10);
        let endDateStr = weekEndDate.toISOString().substring(0, 10);
        effortHeaders.push(`Week (${column}) ${startDateStr}`);
      });

      let [totalProjectCost, totalProjectSTDCost, totalHours, totalSubContractorFees, updatedAllProjectRoleResourcesEffort] = calculateTotalHoursEfforts(allInitialRoleResourseEstimation, allInitialEffortHours);

      updatedAllProjectRoleResourcesEffort.map((resourceEstimation, rowIndex) => {
        let appearanceOrder = !((resourceEstimation.PrjectRoleResourceDetail?.Resource || resourceEstimation.PrjectRoleResourceDetail?.Resource == "") && resourceEstimation.PrjectRoleResourceDetail.AppearanceOrder) ? resourceEstimation.PrjectRoleResourceDetail?.Resource ? `(${resourceEstimation.PrjectRoleResourceDetail.Resource})` : '' : `(${resourceEstimation.PrjectRoleResourceDetail.AppearanceOrder ?? ''})`

        let eachRole: string[] = [resourceEstimation.Phase, resourceEstimation.SubPhase, `${resourceEstimation.PrjectRoleResourceDetail?.PCRRoleRate?.Role.Name}${appearanceOrder}`,
        '' + resourceEstimation.PrjectRoleResourceDetail?.OfferedRate?.toFixed(2), '' + resourceEstimation.TotalHours?.toFixed(1), '' + resourceEstimation.TotalFees?.toFixed(2)];
        weekEffortColumn.map((column) => {
          let effortHours = allInitialEffortHours.filter(item => item.WeekNumber == column && item.RoleResourceEstimateId == resourceEstimation.RoleResourceEstimateId)[0];
          if (effortHours) {
            eachRole.push(effortHours.EffortHours?.toString());
          } else {
            eachRole.push('');
          }
        });
        effortHours[rowIndex] = eachRole;
      });
      var efforts = new CreateEffortReport();
      efforts.writeDataToExcel(currentProjectInfoGblCtx, allProjectRoleResoursesGblCtx, effortHeaders, effortHours);
    }
  }

  const saveAllWeeklyEffortsEvent = async (isAutoSaveCalled?: boolean | false) => {
    if (!saveEffortDisabled) {
      if (allFinalResourceEffort.filter(e => e.RoleResourceEstimateId < 0).length == 0 || isAutoSaveCalled) {
        if (isAutoSaveCalled) {
          setAutoSaveText("Saving..");
        }
        toast.info('Saving resource effort estimations...', { toastId: 'info1', autoClose: 1000, position: toast.POSITION.TOP_CENTER });

        if (updatedRoleResourseEstimations != undefined && updatedRoleResourseEstimations.length > 0) {
          await updateProjectRoleResourceEstimations(updatedRoleResourseEstimations);
        }
        await saveWeeklyEffortsAll(isAutoSaveCalled);
        if (!isAutoSaveCalled) {
          setAllSelectedEffortCell([]);
          setAllPhaseSelectedCell([]);
        }
        setAutoSaveText(saveButtonText);
      } else {
        const errors: { [key: string]: string } = {};
        setValidationErrors({});
        let errorMsg = "Please select role to select row";
        allFinalResourceEffort.filter(e => e.RoleResourceEstimateId < 0).map((rec) => {
          let name = `Role${rec.RoleResourceEstimateId}`
          errors[name] = errorMsg ?? '';
        })
        setValidationErrors(errors);
        if (!isAutoSaveCalled) {
          toast.error("Unable to save, please select a role in the Level of Effort Estimate.", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
      }
    }
  }

  const resetAllWeeklyEfforts = () => {
    if (!resetEffortDisabled) {
      setAllTempEffortHours(allInitialEffortHours);
      setResetEffortDisabled(true);
      setMaxWeekIndex(calculateTotalWeekNumber(allInitialEffortHours));
      setAllSelectedEffortCell([]);
      setUpdatedRoleResourseEstimations([]);
      setAllPhaseSelectedCell([]);
      setAllRoleResourseEstimation(allInitialRoleResourseEstimation);
      setSelectedResourceEstimationRows([]);
      setSelectedWeekNumberColumn(undefined);
      setTempFixForSummaryUpdate(getRandomNumber());
    }
  };

  const confirmWeekDelete = async () => {
    setShowRoleDeleteModal(false);
    setDeleteEstimationDisabled(true)
    if (selectedWeekNumberColumn && selectedWeekNumberColumn > 0) {
      toast.info(`Deleting all '${selectedWeekNumberColumn}' week efforts...`, { toastId: 'info1', autoClose: 2000, position: toast.POSITION.TOP_CENTER });

      let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
      let [isSuccess, message] = await deleteWeeklyEfforts(projId ?? 0, selectedWeekNumberColumn);
      if (isSuccess) {
        await fetchWeeklyEfforts();
      }
      if (isSuccess) {
        setSelectedWeekNumberColumn(undefined);
        setDeleteEstimationDisabled(true);
        return toast.success(`'All ${selectedWeekNumberColumn} Week Efforts Deleted Successfully.'`, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
      }
      else {
        setDeleteEstimationDisabled(false);
        return toast.error("Error occurred", { toastId: 'error1', autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
    }
  };

  const cancelWeekDelete = () => {
    setShowRoleDeleteModal(false);
  };

  const onCloseDuplicateDialog = () => {
    setShowDuplicateEffortEstimationModal(false);
  };

  const weekNumberColumnClicked = (weekNumber: number) => {
    if (!disabledHideOrReadonly) {
      setSelectedResourceEstimationRows([]);
      //multicell selected use duplicate cell copy feature
      if (allSelectedEffortCell?.length > 1) {
        duplicateCellHours(weekNumber);
        setSelectedWeekNumberColumn(undefined);
      } else { //Use column selected feature
        if (selectedWeekNumberColumn != weekNumber) {
          setSelectedWeekNumberColumn(weekNumber);
        } else {
          setSelectedWeekNumberColumn(undefined);
        }
        setAllSelectedEffortCell([]);
      }
    }
  }

  const deleteWeeklyColumn = (weekNumber: number) => {
    if (saveEffortDisabled) {
      if (!deleteEstimationDisabled) {
        if (maxWeekIndex > 1) {
          setWeekToDeleteMessage(`Are you sure to delete week ${weekNumber}'s effort for all ${allRoleResourseEstimation.length} resource(s)?`)
          setShowRoleDeleteModal(true);
        } else {
          return toast.error("Minimum 1 week plan is required", { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
      }
    } else {
      return toast.error("Please save all data, and then delete week.", { toastId: 'error1', style: { backgroundColor: 'white' }, autoClose: 20000, position: toast.POSITION.TOP_CENTER });
    }
  };

  const deleteEffortEstimation = async () => {
    if (!deleteEstimationDisabled) {
      if (selectedResourceEstimationRows.length > 0) { //Resource delete
        setRoleResourceToDeleteMessage(`Are you sure to delete all selected resource estimation's with efforts?`)
        setShowRoleResourceEstimationDeleteModal(true);
      } else if (selectedWeekNumberColumn && selectedWeekNumberColumn > 0) {
        deleteWeeklyColumn(selectedWeekNumberColumn)
      }
    }
  }

  const deleteRoleResourceEstimation = (index: number, resource: IProjectEffortEstimation) => {
    if (!deleteRoleResourceDisabled && resource.RoleResourceEstimateId > 0) {
      setRoleResourceToDeleteMessage(`Are you sure to delete resource ${resource.PrjectRoleResourceDetail?.PCRRoleRate?.Role.Name}- ${resource.PrjectRoleResourceDetail?.Resource}'s with efforts?`)
      setShowRoleResourceEstimationDeleteModal(true);
      setSelectedRoleResourseEstimation(resource);
    } else {
      emptyRowDelete = true;
      setAllRoleResourseEstimation(prevState => {
        return prevState.filter(item => item.RoleResourceEstimateId !== resource.RoleResourceEstimateId);
      });
      setAllTempEffortHours((prevStat) => {
        return prevStat.filter(item => item.RoleResourceEstimateId !== resource.RoleResourceEstimateId);
      })
      setUpdatedRoleResourseEstimations((prevData) => prevData.filter(item => item.RoleResourceEstimateId !== resource.RoleResourceEstimateId));
    }
  };

  const confirmDeleteRoleFromLevelEffortEstimation = async () => {
    await deleteRoleFromLevelEffortEstimation();
    setShowRoleResourceEstimationDeleteModal(false);
  }

  const deleteRoleFromLevelEffortEstimation = async () => {
    let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
    if (projId && selectedResourceEstimationRows) {
      let [isSuccess, message] = [false, ''];
      setLoadingIconEffortGrid(true);
      [isSuccess, message] = await deleteProjectRoleResourceEstimations(projId, selectedResourceEstimationRows);
      if (isSuccess) {
        let allDeletedRoleResourceEstimationIds = [...new Set(selectedResourceEstimationRows.map(res => res.RoleResourceEstimateId))];

        let filteredRoleResEstimations = allRoleResourseEstimation.filter(resource => !allDeletedRoleResourceEstimationIds.includes(resource.RoleResourceEstimateId));
        setAllRoleResourseEstimation(filteredRoleResEstimations);
        setAllInitialRoleResourseEstimation(filteredRoleResEstimations);

        let filteredEffortHours = allTempEffortHours.filter(effortHour => !allDeletedRoleResourceEstimationIds.includes(effortHour.RoleResourceEstimateId));
        setAllTempEffortHours(filteredEffortHours);
        setAllInitialEffortHours(filteredEffortHours);

        let updatedEstimations = updatedRoleResourseEstimations.filter(resource => !allDeletedRoleResourceEstimationIds.includes(resource.RoleResourceEstimateId));
        setUpdatedRoleResourseEstimations(updatedEstimations);

        setTempFixForSummaryUpdate(getRandomNumber());
        await delayStatement(100);
        setTempFixForUpdateSummaryAPI(getRandomNumber());
        setSelectedRoleResourseEstimation(undefined);
        setSelectedResourceEstimationRows([]);
      }
    }
    setLoadingIconEffortGrid(false);
  }

  const cancelRoleResourceModalDelete = () => {
    setShowRoleResourceEstimationDeleteModal(false);
  };

  const calculateTotalHoursEfforts = (allRoleResourseEstimationList: IProjectEffortEstimation[], allEfforts: IProjectEffortHours[]): [number, number, number, number, IProjectEffortEstimation[]] => {
    let totalProjectCost: number = 0;
    let totalProjectSTDCost: number = 0;
    let totalHours: number = 0;
    let totalSubContractorFees: number = 0;
    let qualityError: string[] = [];
    const updatedAllProjectRoleResourcesEffort = allRoleResourseEstimationList.map((eachRoleResourceObject) => {
      let roleTotalHours = setCalculateTotalHoursForProject(eachRoleResourceObject, allEfforts);
      totalHours = totalHours + roleTotalHours;
      let roleTotalCost: number = 0
      let roleSTDTotalCost: number = 0
      if (roleTotalHours && eachRoleResourceObject.PrjectRoleResourceDetail?.PCRRoleRate?.Rate != undefined) {
        roleTotalCost = roleTotalHours * eachRoleResourceObject.PrjectRoleResourceDetail.OfferedRate;
        totalProjectCost = totalProjectCost + roleTotalCost;
        roleSTDTotalCost = roleTotalHours * eachRoleResourceObject.PrjectRoleResourceDetail.STDRate;
        totalProjectSTDCost = totalProjectSTDCost + roleSTDTotalCost;
      } else {
        qualityError.push(`'${eachRoleResourceObject.PrjectRoleResourceDetail?.PCRRoleRate?.Role?.Name}' does not assign hour(s)`);
      }
      let roleSubContractorFees: number | null = null;
      if (eachRoleResourceObject.PrjectRoleResourceDetail?.IsSubContractor && eachRoleResourceObject.PrjectRoleResourceDetail.SubContractorFees && eachRoleResourceObject.PrjectRoleResourceDetail.SubContractorFees > 0) {
        roleSubContractorFees = roleTotalHours * eachRoleResourceObject.PrjectRoleResourceDetail.SubContractorFees;
        totalSubContractorFees = totalSubContractorFees + roleSubContractorFees;
      }

      const updatedProject = { ...eachRoleResourceObject, TotalHours: roleTotalHours, TotalFees: roleTotalCost, TotalSubContractorFees: roleSubContractorFees };
      return updatedProject;
    });
    return [totalProjectCost, totalProjectSTDCost, totalHours, totalSubContractorFees, updatedAllProjectRoleResourcesEffort];
  }

  const setUpdatedProjectEffortSummary = (allRoleResourseEstimationList: IProjectEffortEstimation[], allEfforts: IProjectEffortHours[]): IProjectEffortEstimation[] => {

    let [totalProjectCost, totalProjectSTDCost, totalHours, totalSubContractorFees, updatedAllProjectRoleResourcesEffort] = calculateTotalHoursEfforts(allRoleResourseEstimationList, allEfforts);

    setProjectEffortSummaryGblCtx({
      ProjectId: projectId,
      ProjectCost: totalProjectCost,
      ProjectSTDCost: totalProjectSTDCost,
      QualityError: [],
      TotalHours: totalHours,
      TotalResource: allProjectRoleResoursesGblCtx?.length,
      TotalSubContractorFees: totalSubContractorFees,
      TotalUniqueRole: 10
    });

    if (currentProjectInfoGblCtx) {
      let rAlt = getRisk(currentProjectInfoGblCtx.RiskMultiple, totalProjectCost);
      let rSTD = getRisk(currentProjectInfoGblCtx.RiskMultiple, totalProjectSTDCost);
      setCurrentProjectInfoGblCtx({
        ...currentProjectInfoGblCtx,
        EstimatedServicesFeesSTDRate: totalProjectSTDCost,
        EstimatedServicesFeesAltRate: totalProjectCost,
        RiskAdjustedServicesFees: rAlt,
        RiskAdjustedServicesFeesStdRate: rSTD
      })
      setRefreshProjectHeaderGblCtx(getRandomNumber());
    }

    return updatedAllProjectRoleResourcesEffort;
  }

  const getRisk = (risk?: number | null, fess?: number | null): number | null => {
    let cal: number | null = null;
    if (risk && fess) {
      cal = (risk + 1) * fess;
    }
    return cal;
  };

  const setCalculateTotalHoursForProject = (projectRole: IProjectEffortEstimation, allEfforts: IProjectEffortHours[]): number => {
    let allWeeklyEfforts = allEfforts.filter(item => item.RoleResourceEstimateId == projectRole.RoleResourceEstimateId)
    if (!allWeeklyEfforts) {
      return 0;
    }

    let totalHours = 0;

    for (const effort of allWeeklyEfforts) {
      totalHours += effort.EffortHours;
    }

    return totalHours;
  }

  const setRoleUtilization = () => {
    let roleUtilization = phaseEffort.getRoleUtilizationByPhase(projectEffortSummaryGblCtx, allProjectRoleResoursesGblCtx, allRoleResourseEstimation, null)
    setAllProjectRoleUtilizationGblCtx(roleUtilization);

    let phaseRoleUtilization = phaseEffort.getRoleUtilizationByPhase(projectEffortSummaryGblCtx, allProjectRoleResoursesGblCtx, allRoleResourseEstimation, selectedPhaseGblCtx)
    setAllPhaseRoleUtilizationGblCtx(phaseRoleUtilization);
  }

  const setProjectPhaseUtilization = () => {
    let phaseUtilization = phaseEffort.getProjectPhaseUtilization(allRoleResourseEstimation, allTempEffortHours);
    if (phaseUtilization && phaseUtilization.length > 0) {
      setAllProjectPhaseUtilizationGblCtx(phaseUtilization);
    }
  }

  const calculatedCellSummary = (): SelectedCellSummary | null => {
    let summary: SelectedCellSummary | null = null
    if (allSelectedEffortCell?.length > 0) {
      let total: number = 0;
      let totalCost: number = 0;
      let count: number = 0;
      allSelectedEffortCell.map(eachCell => {
        let objEffort = allTempEffortHours.filter(e => e.RoleResourceEstimateId == eachCell.RoleResourceEstimateId && e.WeekNumber == eachCell.WeekNumber)[0];
        let resource = allRoleResourseEstimation.filter(item => item?.RoleResourceEstimateId == eachCell.RoleResourceEstimateId)[0];

        if (objEffort?.EffortHours > 0) {
          count++;
          total = total + objEffort.EffortHours;
          totalCost = totalCost + objEffort.EffortHours * (resource.PrjectRoleResourceDetail?.OfferedRate ?? 0)
        }
      });
      summary = {
        Sum: total,
        TotalCost: totalCost,
        TotalCell: allSelectedEffortCell.length,
        ValueCell: count
      }
    }
    return summary;
  }

  const selectEffortByRectangleAllMouseCell = (rowIndex: number, weekNumber: number, roleResourceEstimateId: number, isUpdatedEffort: boolean, lastEventName: string) => {
    let firstRecords = allSelectedEffortCell[0];
    //Undo
    if (isUpdatedEffort) {
      createSnapData(allRoleResourseEstimation, allTempEffortHours, false);
    }
    if (firstRecords) { //Horizontal move
      let lowY: number = firstRecords.RowIndex;
      let highY: number = rowIndex;
      if (firstRecords.RowIndex > rowIndex) {
        lowY = rowIndex;
        highY = firstRecords.RowIndex;
      }

      let lowX: number = firstRecords.WeekNumber;
      let highX: number = weekNumber;

      if (firstRecords.WeekNumber > weekNumber) {
        lowX = weekNumber;
        highX = firstRecords.WeekNumber;
      }
      let allSelectedCell: EffortCell[] = [allSelectedEffortCell[0]]
      for (let row = lowY; row <= highY; row++) {
        for (let col = lowX; col <= highX; col++) {
          let recordByIndex = allRoleResourseEstimation[row];
          if (recordByIndex) {
            let recExist = allSelectedCell.some(s => s.WeekNumber == col && s.RowIndex == row)
            if (!recExist) {
              let cell: EffortCell = {
                RoleResourceEstimateId: recordByIndex.RoleResourceEstimateId,
                WeekNumber: col,
                RowIndex: row,
                LastEventName: lastEventName
              }

              allSelectedCell.push(cell);
            }
            if (isUpdatedEffort) {
              let selectedEffort = 0;
              let existingEfforts = allTempEffortHours.filter(item => item.WeekNumber == firstRecords.WeekNumber && item.RoleResourceEstimateId == firstRecords.RoleResourceEstimateId)[0];
              if (existingEfforts) {
                selectedEffort = existingEfforts.EffortHours;
              }
              updateEffortHoursByWeekRoleResourceId(recordByIndex.RoleResourceEstimateId, col, selectedEffort);
            }
          }
        }
      }
      setAllSelectedEffortCell(allSelectedCell);
    }
  }

  const handleEffortMouseMove = (rowIndex: number, weekNumber: number, roleResourceEstimateId: number, weekId: number) => {
    if (isEffortHoursCellSelectionOn && allSelectedEffortCell.length > 0) {
      selectEffortByRectangleAllMouseCell(rowIndex, weekNumber, roleResourceEstimateId, false, 'move');
    }
  };

  const handleEffortMouseUp = (rowIndex: number, weekNumber: number, roleResourceEstimateId: number, weekId: number) => {
    //fill all the cell by same value
    if (!clickedMultiCellCopyButton && !clickedMultiCellCutButton) {
      if (allSelectedEffortCell.length > 0) {
        let isNumberUpdate = allSelectedEffortCell.length > 1 && allSelectedEffortCell[0].LastEventName == 'down'

        selectEffortByRectangleAllMouseCell(rowIndex, weekNumber, roleResourceEstimateId, isNumberUpdate, allSelectedEffortCell[0].LastEventName);
      }
      setSelectedResourceEstimationRows([]);
      setSelectedWeekNumberColumn(undefined);
    }
    setIsEffortHoursCellSelectionOn(false);
  }

  const handleEffortMouseDown = (event: MouseEvent<HTMLButtonElement>, rowIndex: number, weekNumber: number, roleResourceEstimateId: number, weekId: number) => {
    if (!event.shiftKey) {
      let cell: EffortCell = {
        RoleResourceEstimateId: roleResourceEstimateId,
        WeekNumber: weekNumber,
        RowIndex: rowIndex,
        LastEventName: 'down'
      }

      if (allSelectedEffortCell?.length == 0) {
        setAllSelectedEffortCell([cell]);
      } else if (allSelectedEffortCell?.length == 1) {
        let fRec = allSelectedEffortCell[0];
        if (fRec && fRec.RowIndex == rowIndex && fRec.WeekNumber == weekNumber) {
          cell.LastEventName = 'clicked';
          setAllSelectedEffortCell([cell]);
        } else {
          setAllSelectedEffortCell([cell]);
        }
      } else {
        if (!clickedMultiCellCopyButton && !clickedMultiCellCutButton) {
          setAllSelectedEffortCell([cell]);
        }
      }
      setIsEffortHoursCellSelectionOn(true);
    }
  }

  const handleEffortCellClicked = (event: MouseEvent<HTMLButtonElement>, rowIndex: number, weekNumber: number, roleResourceEstimateId: number, weekId: number, isDoubleClicked: boolean) => {
    const shiftKeyPressed = event.shiftKey;
    setDestinationEffortCell(null);
    setAllNewlyCopiedEffortCell([]);
    if (!shiftKeyPressed) {
      if (allSelectedEffortCell?.length == 1) {
        setAllSelectedEffortCell([{
          RoleResourceEstimateId: roleResourceEstimateId,
          WeekNumber: weekNumber,
          RowIndex: rowIndex,
          //ColumnIndex: columnIndex,
          LastEventName: 'clicked'
        }]);
      } else {
        if (clickedMultiCellCopyButton || clickedMultiCellCutButton) {
          setDestinationEffortCell({
            RoleResourceEstimateId: roleResourceEstimateId,
            WeekNumber: weekNumber,
            RowIndex: rowIndex,
            //ColumnIndex: columnIndex,
            LastEventName: 'destination-clicked'
          });
          setDisplayMultiCellPasteButton(allSelectedEffortCell.length > 1);
        } else {
          setAllSelectedEffortCell([])
        }
      }
      setIsEffortHoursCellSelectionOn(false);
    }
    if (isDoubleClicked) {
      setAllSelectedEffortCell([]);
      setDisplayMultiCellPasteButton(false)
    }

    if (shiftKeyPressed && !isDoubleClicked) {
      setDisplayMultiCellPasteButton(true)
      selectEffortByRectangleAllMouseCell(rowIndex, weekNumber, 0, false, 'move');
    }
  }

  const handleEffortPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('Text');
    if (pasteData.toLowerCase().includes('e')) {
      e.preventDefault();
    }
  };

  const handleEffortKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, effortInputIndex: number, roleResourceEstimateId: number, rowIndex: number, weekNumber: number) => {
    let targetIndex = effortInputIndex;
    let totalRowCount = allRoleResourseEstimation.length;
    let cols = maxWeekIndex;
    if (event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 's') { //Use CTRL+S to save effort
      event.preventDefault();
      saveAllWeeklyEffortsEvent();
    } else if ((event.ctrlKey || event.metaKey) && event.key == 'Enter') {
      addRoleInsideEffort(rowIndex);
      totalRowCount = totalRowCount + 1;
      setControlEnterPressedRow(rowIndex + 2);
    } else if (event.key == 'Enter') {
      if (rowIndex == totalRowCount - 1) {
        addRoleInsideEffort(totalRowCount - 1);
        totalRowCount = totalRowCount + 1;
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'c') {
      if (allSelectedEffortCell?.length > 1) {
        setClickedMultiCellCopyButton(true);
        setClickedMultiCellCutButton(false);
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'x') {
      if (allSelectedEffortCell?.length > 1) {
        setClickedMultiCellCopyButton(false);
        setClickedMultiCellCutButton(true);
      }
    }

    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'v') {
      if (allSelectedEffortCell?.length > 1) {
        event.preventDefault();
        pasteSelectedCellHours();
      }
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'delete') {
      if (allSelectedEffortCell?.length > 1) {
        clearSelectedCellHours();
      }
    }
    if (displayMultiCellUndoButton && (event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'z') {
      undoSelectedCellHours();
    }
    if (event.shiftKey && event.key.toLowerCase() == 'enter' && allSelectedEffortCell.length > 1) {
      fillSelectedCell(allSelectedEffortCell, allSelectedEffortCell[0]);
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'i') {
      addWeekEffort(false);
    }

    if (allSelectedEffortCell.length > 1) {
      setDestinationEffortCell({
        RoleResourceEstimateId: roleResourceEstimateId,
        WeekNumber: weekNumber,
        RowIndex: rowIndex,
        LastEventName: 'destination-clicked'
      });
    }

    if (event.shiftKey && event.key == 'ArrowRight') {
      if (allSelectedEffortCell.length > 0) {
        selectEffortByRectangleAllMouseCell(rowIndex, weekNumber + 1, roleResourceEstimateId, false, 'move');
      }
    }
    if (event.shiftKey && event.key == 'ArrowLeft') {
      if (allSelectedEffortCell.length > 0) {
        selectEffortByRectangleAllMouseCell(rowIndex, weekNumber - 1, roleResourceEstimateId, false, 'move');
      }
    }
    if (event.shiftKey && event.key == 'ArrowDown') {
      if (allSelectedEffortCell.length > 0) {
        selectEffortByRectangleAllMouseCell(rowIndex + 1, weekNumber, roleResourceEstimateId, false, 'move');
      }
    }
    if (event.shiftKey && event.key == 'ArrowUp') {
      if (allSelectedEffortCell.length > 0) {
        selectEffortByRectangleAllMouseCell(rowIndex - 1, weekNumber, roleResourceEstimateId, false, 'move');
      }
    }

    let arrowButtonPressed = false;
    switch (event.key) {
      case 'ArrowRight':
        arrowButtonPressed = true;
        targetIndex = (effortInputIndex + 1) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'ArrowLeft':
        arrowButtonPressed = true;
        targetIndex = (effortInputIndex - 1 + totalRowCount * cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'Enter':
      case 'ArrowDown':
        arrowButtonPressed = true;
        targetIndex = (effortInputIndex + cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'ArrowUp':
        arrowButtonPressed = true;
        targetIndex = (effortInputIndex - cols + totalRowCount * cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      default:
        break;
    }
    if (arrowButtonPressed && effortsTextBoxRefs.current[targetIndex]) {
      effortsTextBoxRefs.current[targetIndex]?.focus();
    }
  };

  const handlePhaseKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, roleResourceEstimate: IProjectEffortEstimation, currentPhaseIndex: number, rowIndex: number, colIndex: number) => {
    let targetIndex = currentPhaseIndex;
    let totalRowCount = allRoleResourseEstimation.length;
    let cols = totalPhaseCol;
    let isCotrolEnterPressed = false;
    if ((event.ctrlKey || event.metaKey) && event.key == 's') {
      event.preventDefault();
      saveAllWeeklyEffortsEvent();
    }

    if ((event.ctrlKey || event.metaKey) && event.key == 'Enter') {
      addRoleInsideEffort(rowIndex);
      setControlEnterPressedRow(rowIndex + 2);
      totalRowCount = totalRowCount + 1;
      rowIndex = totalRowCount;
      isCotrolEnterPressed = true;
    } else if (event.key == 'Enter') {
      if (rowIndex == totalRowCount - 1) {
        addRoleInsideEffort(totalRowCount - 1);
        totalRowCount = totalRowCount + 1;
      }
    }

    let arrowButtonPressed = false;
    switch (event.key) {
      case 'ArrowRight':
        arrowButtonPressed = true;
        targetIndex = (currentPhaseIndex + 1) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'ArrowLeft':
        arrowButtonPressed = true;
        targetIndex = (currentPhaseIndex - 1 + totalRowCount * cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'Enter':
      case 'ArrowDown':
        arrowButtonPressed = true;
        targetIndex = (currentPhaseIndex + cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      case 'ArrowUp':
        arrowButtonPressed = true;
        targetIndex = (currentPhaseIndex - cols + totalRowCount * cols) % (totalRowCount * cols);
        event.preventDefault();
        break;
      default:
        break;
    }
    if (arrowButtonPressed && !isCotrolEnterPressed && phaseTextBoxRefs.current[targetIndex]) {
      phaseTextBoxRefs.current[targetIndex]?.focus();
    }
  }

  function isEffortRowSelectedColorRequired(roleResourceEstimateId: number, weekId: number, weekNumber: number): boolean {
    return allSelectedEffortCell.some(item => item.RoleResourceEstimateId == roleResourceEstimateId && item.WeekNumber == weekNumber)
  }

  function isPhaseRowSelectedColorRequired(roleResourceEstimateId: number, colIndex: number): boolean {
    let selected = allPhaseSelectedCell.some(item => item?.RoleResourceEstimateId == roleResourceEstimateId && item.ColumnIndex == colIndex)
    return selected;
  }

  const updateEffortHoursByWeekRoleResourceId = (roleResourceEstimateId: number, weekNumber: number, newEffortHours: number) => {
    setAutoSaveBlinkCount(-1);
    setAllTempEffortHours(prevState => {
      return prevState.map(cell => {
        if (cell.RoleResourceEstimateId == roleResourceEstimateId && cell.WeekNumber == weekNumber) {
          return { ...cell, EffortHours: newEffortHours };
        }
        return cell;
      });
    });

    let existingEfforts = allTempEffortHours.filter(item => item.WeekNumber == weekNumber && item.RoleResourceEstimateId == roleResourceEstimateId)[0];
    if (!existingEfforts) {
      let newEffort = getProjectEffortHours(roleResourceEstimateId, 0, weekNumber, newEffortHours);
      [newEffort.DateFrom, newEffort.DateTo] = getWeekDateRangeByWeekNumber(currentProjectInfoGblCtx?.StartDate ?? undefined, weekNumber);
      setAllTempEffortHours((prevEffortHours) => [...prevEffortHours, newEffort]);
    }
  }

  const getDistinctEfforts = (allEfforts: IProjectEffortHours[]) => {
    const distinctEfforts = allEfforts.reduce((acc: IProjectEffortHours[], curr: IProjectEffortHours) => {
      const existingEffortIndex = acc.findIndex(
        (rec) => rec.WeekNumber == curr.WeekNumber && rec.RoleResourceEstimateId == curr.RoleResourceEstimateId
      );
      if (existingEffortIndex == -1) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return distinctEfforts;
  };

  const findAddedUpdatedDeletedEffortHours = (): IProjectEffortHours[] => {
    setAllFinalResourceEffort([]);
    // Check for added and updated effort hours
    let deltaEfforts: IProjectEffortHours[] = [];

    allTempEffortHours.forEach(tempEffortHour => {
      const matchingInitialEffortHour = allInitialEffortHours.find(item =>
        item.RoleResourceEstimateId == tempEffortHour.RoleResourceEstimateId
        && item.WeekNumber == tempEffortHour.WeekNumber
        && item.WeekId == tempEffortHour.WeekId);

      let record = { ...tempEffortHour };

      if (!matchingInitialEffortHour) {
        if (tempEffortHour.EffortHours > 0) {
          deltaEfforts.push(record);
        }
      } else if (matchingInitialEffortHour.EffortHours != tempEffortHour.EffortHours) {
        deltaEfforts.push(record);
      }
    });

    // Check for deleted effort hours
    allInitialEffortHours.forEach(initialEffortHour => {
      const matchingTempEffortHour = allTempEffortHours.find(item =>
        item.RoleResourceEstimateId == initialEffortHour.RoleResourceEstimateId
        && item.WeekNumber == initialEffortHour.WeekNumber
        && item.WeekId == initialEffortHour.WeekId);

      if (!matchingTempEffortHour) {
        let record = { ...initialEffortHour };
        deltaEfforts.push(record);
      }
    });
    let distinctEfforts = getDistinctEfforts(deltaEfforts);
    setAllFinalResourceEffort(distinctEfforts);
    return deltaEfforts;
  }

  const hadleRoleResourceEstimationRowClick = (index: number, resource: IProjectEffortEstimation) => {
    if (!disabledHideOrReadonly) {
      setSelectedWeekNumberColumn(undefined);
      const errors: { [key: string]: string } = {};
      setValidationErrors({});
      if (resource && resource?.RoleResourceEstimateId > 0) {
        if (selectedResourceEstimationRows.filter((f) => f.RoleResourceEstimateId == resource.RoleResourceEstimateId)?.length > 0) {
          setSelectedResourceEstimationRows(selectedResourceEstimationRows.filter((res) => res.RoleResourceEstimateId !== resource.RoleResourceEstimateId));
        } else {
          setSelectedResourceEstimationRows([...selectedResourceEstimationRows, resource]);
        }
      } else if (!emptyRowDelete) {
        let errorMsg = "Please select role to select row";
        let name = `Role${resource.RoleResourceEstimateId}`
        errors[name] = errorMsg ?? '';
        setValidationErrors(errors);
        toast.error(errorMsg, { toastId: "error1", autoClose: 20000, position: toast.POSITION.TOP_CENTER });
      }
    }
  }

  const duplicateRoleResorceEfforts = async (isCopyResourceEffortRowData: boolean) => {
    setShowDuplicateEffortEstimationModal(false);
    if (!duplicateEstimationDisabled && selectedResourceEstimationRows.length > 0) {
      let projId = projectId != undefined && projectId > 0 ? projectId : currentProjectIdGblCtx;
      if (projId) {
        setLoadingIconEffortGrid(true);
        setSelectedResourceEstimationRows(selectedResourceEstimationRows.sort((a, b) => a.Order - b.Order))
        let [isSuccess, message] = await duplicateProjectRoleResourceEstimationsInfo(selectedResourceEstimationRows, projId, isCopyResourceEffortRowData)
        if (isSuccess) {
          let newlyResIds = message.split('|');
          await copyResourceEffortRowUnSavedData(projId, newlyResIds, isCopyResourceEffortRowData);

          toast.success(`Effort Duplicated Successfully`, { toastId: 'success1', autoClose: 5000, position: toast.POSITION.TOP_CENTER });
        } else {
          toast.error("Error:" + message, { toastId: "error1", autoClose: 20000, position: toast.POSITION.TOP_CENTER });
        }
      }
      setLoadingIconEffortGrid(false);
    }
  }

  const copyResourceEffortRowUnSavedData = async (projectId: number, createdEffortEstimation: string[], isCopyResourceEffortRowData: boolean) => {

    const newlyCreatedEstimationIds: number[] = createdEffortEstimation.map(pair => {
      const [, rightId] = pair.split(',');
      return parseInt(rightId, 10);
    });

    if (projectId && newlyCreatedEstimationIds) {
      let allNewlyCreatedEstimations = await getProjectRoleResourceEstimationByIds(projectId, newlyCreatedEstimationIds);
      if (allNewlyCreatedEstimations) {
        let newlyCreatedEstimationObjs: IProjectEffortEstimation[] = [];
        let copiedWeeklyEfforts: IProjectEffortHours[] = [];
        createdEffortEstimation.map((res: any) => {
          let rows = res.split(',');
          let newlyCreatedEstimationObj = allNewlyCreatedEstimations?.filter((s) => s.RoleResourceEstimateId == parseInt(rows[1]))[0];
          if (newlyCreatedEstimationObj && newlyCreatedEstimationObj.RoleResourceEstimateId > 0) {
            if (isCopyResourceEffortRowData) {
              let existingUnsavedRecord = updatedRoleResourseEstimations?.filter((s) => s.RoleResourceEstimateId == parseInt(rows[0]))[0];
              if (existingUnsavedRecord) {
                newlyCreatedEstimationObj.Phase = existingUnsavedRecord?.Phase;
                newlyCreatedEstimationObj.SubPhase = existingUnsavedRecord?.SubPhase;
              } else {
                let existingEstimationRecord = allRoleResourseEstimation?.filter((s) => s.RoleResourceEstimateId == parseInt(rows[0]))[0];
                if (existingEstimationRecord) {
                  newlyCreatedEstimationObj.Phase = existingEstimationRecord?.Phase;
                  newlyCreatedEstimationObj.SubPhase = existingEstimationRecord?.SubPhase;
                }
              }
              let allTempEffortToBeCopy = allTempEffortHours.filter((s) => s.RoleResourceEstimateId == parseInt(rows[0]));
              if (allTempEffortToBeCopy) {
                allTempEffortToBeCopy.map((toCopyEffort) => {
                  let copiedEffort = { ...toCopyEffort };
                  copiedEffort.RoleResourceEstimateId = newlyCreatedEstimationObj?.RoleResourceEstimateId ?? 0;
                  copiedEffort.WeekId = 0;
                  copiedWeeklyEfforts.push(copiedEffort)
                });
              }
            }
            newlyCreatedEstimationObjs.push(newlyCreatedEstimationObj);
          }

        });
        if (newlyCreatedEstimationObjs) {
          setUpdatedRoleResourseEstimations([...updatedRoleResourseEstimations, ...newlyCreatedEstimationObjs]);
          setAllRoleResourseEstimation([...allRoleResourseEstimation, ...newlyCreatedEstimationObjs]);
          setAllInitialRoleResourseEstimation([...allRoleResourseEstimation, ...newlyCreatedEstimationObjs]);
        }
        if (copiedWeeklyEfforts) {
          setAllTempEffortHours([...allTempEffortHours, ...copiedWeeklyEfforts]);
        }
      }
    }
  }

  const findUpdatedRoleResourceEstimationList = () => {
    try {
      const uniqueRoles = Array.from(new Set(allPhaseSelectedCell.map(role => role.RoleResourceEstimateId)))
        .map(roleResourceEstimateId => allPhaseSelectedCell.find(role => role.RoleResourceEstimateId == roleResourceEstimateId));

      uniqueRoles.map((cell) => {
        var matchedResource = allRoleResourseEstimation.filter((item) => item.RoleResourceEstimateId == cell?.RoleResourceEstimateId)[0];
        var initialResource = allInitialRoleResourseEstimation.filter((item) => item.RoleResourceEstimateId == cell?.RoleResourceEstimateId)[0];
        if ((matchedResource?.Phase != undefined && matchedResource?.Phase != initialResource?.Phase) || (matchedResource?.SubPhase != undefined && matchedResource?.SubPhase != initialResource?.SubPhase)) {
          addOrUpdatedToRoleResourceEstimationList(matchedResource);
        }
      })
    } catch (error) {
      console.error('error at findUpdatedRoleResourceEstimationList:', error);
    }
  }

  const handlePhaseMouseDown = (roleResourceEstimateId: number, rowIndex: number, columnIndex: number) => {
    setAllPhaseSelectedCell([]);
    if (roleResourceEstimateId > 0) {
      setAllPhaseSelectedCell([{
        RoleResourceEstimateId: roleResourceEstimateId,
        RowIndex: rowIndex,
        ColumnIndex: columnIndex
      }]);
      let clickedRes = allRoleResourseEstimation.filter(item => item?.RoleResourceEstimateId == roleResourceEstimateId)[0];
      if (columnIndex == 1)
        setSelectedPhaseText(clickedRes.Phase)
      if (columnIndex == 2)
        setSelectedPhaseText(clickedRes.SubPhase)
    }
    setIsPhaseCellSelectionOn(true);
  };

  const handlePhaseMouseUp = (roleResourceEstimateId: number, rowIndex: number, columnIndex: number) => {
    //fill all the cell by same value
    if (allPhaseSelectedCell.length > 0) {
      selectPhaseByRectangleAllMouseCell(rowIndex, columnIndex, roleResourceEstimateId, true);
      setTempFixForPhaseCellUpdate(getRandomNumber());
    }

    setIsPhaseCellSelectionOn(false);
    setSelectedPhaseText('');
  }

  const handlePhaseMouseMove = (roleResourceEstimateId: number, rowIndex: number, columnIndex: number) => {
    if (isPhaseCellSelectionOn && allPhaseSelectedCell.length > 0) {
      selectPhaseByRectangleAllMouseCell(rowIndex, columnIndex, roleResourceEstimateId, false);
    }
  };

  const selectPhaseByRectangleAllMouseCell = (rowIndex: number, columnIndex: number, roleResourceEstimateId: number, isUpdatedPhase: boolean) => {
    let firstRecords = allPhaseSelectedCell[0];
    let existingResource = allRoleResourseEstimation.filter(item => item?.RoleResourceEstimateId == firstRecords.RoleResourceEstimateId)[0];
    setAllPhaseSelectedCell([allPhaseSelectedCell[0]]);
    if (firstRecords) { //Horizontal move
      let lowY: number = firstRecords.RowIndex;
      let highY: number = rowIndex;
      if (firstRecords.RowIndex > rowIndex) {
        lowY = rowIndex;
        highY = firstRecords.RowIndex;
      }

      let lowX: number = firstRecords.ColumnIndex;
      let highX: number = columnIndex;

      if (firstRecords.ColumnIndex > columnIndex) {
        lowX = columnIndex;
        highX = firstRecords.ColumnIndex;
      }
      for (let row = lowY; row <= highY; row++) {
        for (let col = lowX; col <= highX; col++) {
          let recordRoleResourceByIndex = allRoleResourseEstimation[row];
          if (recordRoleResourceByIndex) {
            setAllPhaseSelectedCell((preRecords) => [...preRecords, {
              RoleResourceEstimateId: recordRoleResourceByIndex.RoleResourceEstimateId,
              RowIndex: row,
              ColumnIndex: col
            }]);
            if (isUpdatedPhase && col == 1) {
              updatePhaseByWeekRoleResourceId(recordRoleResourceByIndex.RoleResourceEstimateId, selectedPhaseText);
            }
            if (isUpdatedPhase && col == 2) {
              updateSubPhaseByWeekRoleResourceId(recordRoleResourceByIndex.RoleResourceEstimateId, selectedPhaseText);
            }
          }
        }
      }
    }
  }

  const updatePhaseByWeekRoleResourceId = (roleResourceEstimateId: number, newPhaseText: string) => {
    setAllRoleResourseEstimation(prevState => {
      return prevState.map(cell => {
        if (cell.RoleResourceEstimateId == roleResourceEstimateId) {
          let resource = { ...cell, Phase: newPhaseText };
          return resource;
        }
        return cell;
      });
    });
  }

  const updateSubPhaseByWeekRoleResourceId = (roleResourceEstimateId: number, newPhaseText: string) => {
    setAutoSaveBlinkCount(-1);
    setAllRoleResourseEstimation(prevState => {
      return prevState.map(cell => {
        if (cell.RoleResourceEstimateId == roleResourceEstimateId) {
          return { ...cell, SubPhase: newPhaseText };
        }
        return cell;
      });
    });
  }

  const unselectAllSelectedEstimationRow = () => {
    if (selectedResourceEstimationRows.length > 0) {
      setSelectedResourceEstimationRows([]);
      setSelectedWeekNumberColumn(undefined);
    }
  }

  const duplicateCellHours = (column: number) => {
    if (allSelectedEffortCell?.length > 1) {
      let isAllDiffColumn = allSelectedEffortCell.some(c => c.WeekNumber != allSelectedEffortCell[0].WeekNumber);
      if (!isAllDiffColumn) {
        allSelectedEffortCell.map(eachCell => {
          let hours = allTempEffortHours.filter(e => e.RoleResourceEstimateId == eachCell.RoleResourceEstimateId && e.WeekNumber == eachCell.WeekNumber)[0]?.EffortHours
          if (hours > 0) {
            updateEffortHoursByWeekRoleResourceId(eachCell.RoleResourceEstimateId, column, hours)
          }
        })
      }
    }
  }

  const copySelectedCellHours = () => {
    if (allSelectedEffortCell?.length > 1) {
      setClickedMultiCellCopyButton(true);
    } else {
      setClickedMultiCellCopyButton(false)
    }
    setClickedMultiCellCutButton(false);
  }

  const cutSelectedCellHours = () => {
    if (allSelectedEffortCell?.length > 1) {
      setClickedMultiCellCutButton(true)

    } else {
      setClickedMultiCellCutButton(false)
    }
    setClickedMultiCellCopyButton(false)
  }

  const clearSelectedCellHours = () => {
    if (allSelectedEffortCell?.length > 1) {
      clearSelectedCellHoursByEmpty(allSelectedEffortCell);
      setAllSelectedEffortCell([]);
      setAllNewlyCopiedEffortCell([]);
    }
    setDisplayMultiCellUndoButton(true);
  }

  const clearSelectedCellHoursByEmpty = (allSelectedEffortCell: EffortCell[]) => {
    if (allSelectedEffortCell?.length > 1) {
      createSnapData(allRoleResourseEstimation, allTempEffortHours, false);
      allSelectedEffortCell.map(eachCell => {
        let hours = allTempEffortHours.filter(e => e.RoleResourceEstimateId == eachCell.RoleResourceEstimateId && e.WeekNumber == eachCell.WeekNumber)[0]?.EffortHours
        if (hours > 0) {
          updateEffortHoursByWeekRoleResourceId(eachCell.RoleResourceEstimateId, eachCell.WeekNumber, 0)
        }
      })
    }
  }

  const createSnapData = (roleResources: IProjectEffortEstimation[], weeklyEfforts: IProjectEffortHours[], isEnterDataByKeyPressed: boolean) => {
    if (isEnterDataByKeyPressed) {
      setKeyPressSnapCount(keyPressSnapCount + 1);
    }

    if (!isEnterDataByKeyPressed || keyPressSnapCount == totalKeyPressCountSnap) {
      let allUndoData = [...undoEffortCellSnaps];
      if (allUndoData.length == 20) {
        allUndoData.shift();
      }
      let snap: UndoProperty = {
        ResourceEstimation: [...roleResources.map(obj => ({ ...obj }))],
        EffortHours: [...weeklyEfforts.map(obj => ({ ...obj }))]
      }
      allUndoData.push(snap);
      setUndoEffortCellSnaps(allUndoData);
      setKeyPressSnapCount(0);
    }
  }

  const pasteSelectedCellHours = () => {
    if (destinationEffortCell && allSelectedEffortCell?.length > 1) {
      executePasteData(destinationEffortCell, allSelectedEffortCell, true);
    }
  }

  const undoSelectedCellHours = () => {
    if (undoEffortCellSnaps?.length > 0) {
      var topRec = undoEffortCellSnaps[undoEffortCellSnaps.length - 1];
      if (topRec) {
        setAllTempEffortHours([...topRec.EffortHours?.map(obj => ({ ...obj }))]);
        setAllRoleResourseEstimation([...topRec.ResourceEstimation?.map(obj => ({ ...obj }))]);
      }
      undoEffortCellSnaps.pop();
      setDisplayMultiCellUndoButton(false);
      setDisplayMultiCellPasteButton(false);
      setDisplayMultiCellCopyButton(false)
      setDisplayMultiCellCutButton(false)
      setDisplayMultiCellClearButton(false)
      setDestinationEffortCell(null);
      setAllSelectedEffortCell([]);
      setAllNewlyCopiedEffortCell([]);
    }
  }

  const fillSelectedCell = (allSelectedEffortCell: EffortCell[], copyFromCell: EffortCell) => {
    if (allSelectedEffortCell?.length > 1) {
      let existingEfforts = allTempEffortHours.filter(item => item.WeekNumber == copyFromCell.WeekNumber && item.RoleResourceEstimateId == copyFromCell.RoleResourceEstimateId)[0];
      allSelectedEffortCell.map(eachCell => {
        updateEffortHoursByWeekRoleResourceId(eachCell.RoleResourceEstimateId, eachCell.WeekNumber, existingEfforts?.EffortHours)
      });
    }
  }

  const executePasteData = (destinationCell: EffortCell | null, allSelectedEffortCell: EffortCell[], createNewRowColIfNothere: boolean) => {
    if (destinationCell && destinationCell.WeekNumber > 0 && allSelectedEffortCell?.length > 1) {
      let allResources = [...allRoleResourseEstimation];
      let minCol = Math.min(...allSelectedEffortCell.map(role => role.WeekNumber));
      let maxCol = Math.max(...allSelectedEffortCell.map(role => role.WeekNumber));
      let columnNumberToBeCopy = maxCol - minCol;

      let minRow = Math.min(...allSelectedEffortCell.map(role => role.RowIndex));
      let maxRow = Math.max(...allSelectedEffortCell.map(role => role.RowIndex));
      let rowNumberToBeCopy = maxRow - minRow;
      //Create New Column If required
      if (createNewRowColIfNothere && (destinationCell.WeekNumber + columnNumberToBeCopy) > maxWeekIndex) {
        //Request for confirmation
        for (let sCol = 0; sCol < ((destinationCell.WeekNumber + columnNumberToBeCopy) - maxWeekIndex); sCol++) {
          addWeekEffort(false);
        }
      }
      let maxOrder = Math.max(...allResources.map(role => role.Order));
      //Create New Row If required
      if (createNewRowColIfNothere && (destinationCell.RowIndex + rowNumberToBeCopy + 1) > allRoleResourseEstimation.length) {
        //Request for confirmation
        for (let sRow = 0; sRow <= ((destinationCell.RowIndex + rowNumberToBeCopy) - allRoleResourseEstimation.length); sRow++) {
          let prrEstimation = initialDefaultIProjectEffortEstimation();
          prrEstimation.RoleResourceEstimateId = -(getRandomNumber());
          prrEstimation.Order = maxOrder + sRow + 1;
          prrEstimation.ProjectId = currentProjectIdGblCtx ?? 0;
          allResources.push(prrEstimation)
        }
        setAllRoleResourseEstimation(allResources)
      }
      setConfirmCellCopyRefreshRandomNumber(getRandomNumber());
    }
  }

  const fillCopiedEffortDataNewDestination = (destinationCell: EffortCell | null, allSelectedEffortCell: EffortCell[]) => {
    if (destinationCell && destinationCell.WeekNumber > 0 && allSelectedEffortCell?.length > 1) {
      createSnapData(allRoleResourseEstimation, allTempEffortHours, false);
      let minCol = Math.min(...allSelectedEffortCell.map(role => role.WeekNumber));
      let maxCol = Math.max(...allSelectedEffortCell.map(role => role.WeekNumber));
      let columnNumberToBeCopy = maxCol - minCol;

      let minRow = Math.min(...allSelectedEffortCell.map(role => role.RowIndex));
      let maxRow = Math.max(...allSelectedEffortCell.map(role => role.RowIndex));
      let rowNumberToBeCopy = maxRow - minRow;
      let allCopiedCell: EffortCell[] = [];
      allSelectedEffortCell.map(eachCell => {
        let hours = allTempEffortHours.filter(e => e.RoleResourceEstimateId == eachCell.RoleResourceEstimateId && e.WeekNumber == eachCell.WeekNumber)[0]?.EffortHours
        if (hours > 0) {
          let newColIndex = getToCopyColIndex(minCol, destinationCell.WeekNumber, eachCell.WeekNumber);
          let [newRowIndex, roleResourceEstimateId] = getToCopyResourceEstimationId(minRow, destinationCell.RowIndex, eachCell.RowIndex);
          updateEffortHoursByWeekRoleResourceId(roleResourceEstimateId, newColIndex, hours)
          allCopiedCell.push({
            RoleResourceEstimateId: roleResourceEstimateId,
            //WeekId: 0,
            WeekNumber: newColIndex,
            RowIndex: newRowIndex,
            //ColumnIndex: newColIndex,
            LastEventName: 'Copied'
          });
        }
      });
      setAllNewlyCopiedEffortCell(allCopiedCell);
      setDisplayMultiCellUndoButton(true);
    }
  }

  const getToCopyColIndex = (selectedCellMinColIndex: number, destinationColIndex: number, currentCellColInxex: number): number => {
    let newIndex = destinationColIndex + currentCellColInxex - selectedCellMinColIndex;
    return newIndex;
  }

  const getToCopyResourceEstimationId = (selectedCellMinRowIndex: number, destinationRowIndex: number, currentCellRowInxex: number): [number, number] => {
    let newIndex = destinationRowIndex + currentCellRowInxex - selectedCellMinRowIndex;
    let roleResourceEstimateId = -1;
    let recordByIndex = allRoleResourseEstimation[newIndex];
    if (recordByIndex) {
      roleResourceEstimateId = recordByIndex.RoleResourceEstimateId;
    }
    return [newIndex, roleResourceEstimateId];
  }

  const handleResourceEffortsScroll = useCallback(() => {
    setTimeout(function () {
      if (!tableResourceEffortRef.current) {
        return;
      }
      const displayBufferRowNumber = 3;
      const displayBufferColumnNumber = 3;
      const displayColumnNumber = 30;

      const { scrollTop, scrollLeft, clientHeight, clientWidth } = tableResourceEffortRef.current;
      // Calculate visible row range
      const startRow = Math.floor(scrollTop / cellHeight);
      const endRow = Math.min(startRow + Math.ceil(clientHeight / cellHeight), allRoleResourseEstimation.length);

      const actualColWidth = 57;
      // Calculate visible column range
      const startCol = Math.floor(scrollLeft / actualColWidth);
      const endCol = Math.min(startCol + Math.ceil(clientWidth / actualColWidth), maxWeekIndex);
      setVisibleResourceEffortRange({
        minResourceRow: startRow - displayBufferRowNumber,
        maxResourceRow: endRow + displayBufferRowNumber,
        minEffortCol: startCol - displayBufferColumnNumber,
        maxEffortCol: endCol + displayBufferColumnNumber,
      });
    }, 500);
  }, [cellWidth, cellHeight, allRoleResourseEstimation.length, maxWeekIndex]);

  return (
    <>
      <div>
        <UseUnsavedChanges isFormDirty={!saveEffortDisabled} />
      </div>
      <div>
        <ConfirmationModal
          show={showRoleDeleteModal}
          title="Confirm Weekly Effort Delete"
          message={weekToDeleteMessage}
          onClose={() => setShowRoleDeleteModal(false)}
          onCancel={cancelWeekDelete}
          onConfirm={confirmWeekDelete}
        />
      </div>
      <ConfirmationModal
        show={showDuplicateEffortEstimationModal}
        title="Duplicate Effort?"
        message={"Would you like to copy the effort associated with the role(s) you are duplicating?"}
        onCancel={() => duplicateRoleResorceEfforts(false)}
        onConfirm={() => duplicateRoleResorceEfforts(true)}
        onClose={onCloseDuplicateDialog}
        showCloseButton={false}
        cancelText={"Duplicate Role Only"}
        confirmText={"Duplicate Role and Effort"}
      />
      <div>
        <ConfirmationModal
          show={showRoleResourceEstimationDeleteModal}
          title="Confirm Role Resource Estimation Delete"
          message={roleResourceToDeleteMessage}
          onClose={() => setShowRoleResourceEstimationDeleteModal(false)}
          onCancel={cancelRoleResourceModalDelete}
          onConfirm={confirmDeleteRoleFromLevelEffortEstimation}
        />
      </div>

      {disabledHideOrReadonly && <div className="row" style={{ paddingBottom: '5px' }}>
        <div className="d-flex justify-content-between">
          <table style={{ width: '20%' }}>
            <tr>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={downloadEfforts}
                    className={`w-100 ${resetEffortDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: addEffortDisabled ? 'gray' : '', cursor: addEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-download"></i> Download
                  </a>}
              </td>
              <td className="align-middle">
                {allRoleResourseEstimation.length > 0 && <Form.Check
                  style={{ marginTop: '0px', marginBottom: '0px', cursor: 'pointer', height: '24px' }}
                  type="switch"
                  id="resource-estimate-phase-color"
                  label="Phase Color"
                  title="Phase Color"
                  checked={resourcePhaseColor}
                  onChange={() => setResourcePhaseColor(!resourcePhaseColor)}
                />
                }
              </td>
              <td>
                {allProjectRoleResoursesGblCtx.length > 0 &&
                  <a onClick={() => hardRefreshEffortEstimations(true, true)}
                    title="Refresh data from database"
                    className={`w-100 ${addRefreshDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: addRefreshDisabled ? 'gray' : '', cursor: addRefreshDisabled ? 'not-allowed' : 'pointer' }}>
                    <i style={{ fontSize: "15px" }} className="fa">&#xf021;</i> Refresh
                  </a>
                }
              </td>
            </tr>
          </table>
        </div>
      </div>
      }
      {!disabledHideOrReadonly && <div className="row" style={{ paddingBottom: '5px' }}>
        <div className="d-flex justify-content-between">
          <table style={{ width: '100%', height: '26px' }}>
            <tr>
              <td>
                {allProjectRoleResoursesGblCtx.length > 0 &&
                  <a onClick={() => addRoleInsideEffort(allRoleResourseEstimation.length - 1)}
                    title="Select any row to insert an empty row below the selected row. Short cut key (Ctrl+Enter)"
                    className={`w-100 ${addEffortDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: addEffortDisabled ? 'gray' : '', cursor: addEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-plus"></i> Add Resource
                  </a>
                }
              </td>
              <td>
                {allProjectRoleResoursesGblCtx.length > 0 &&
                  <a onClick={() => hardRefreshEffortEstimations(true, true)}
                    title="Refresh data from database"
                    className={`w-100 ${addRefreshDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: addRefreshDisabled ? 'gray' : '', cursor: addRefreshDisabled ? 'not-allowed' : 'pointer' }}>
                    <i style={{ fontSize: "15px" }} className="fa">&#xf021;</i> Refresh
                  </a>
                }
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={() => setShowDuplicateEffortEstimationModal(true)} className={`w-100 ${duplicateEstimationDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: duplicateEstimationDisabled ? 'gray' : '', cursor: duplicateEstimationDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-copy"></i> Duplicate
                  </a>
                }
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 && (<a onClick={() => moveRoleUP()} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: disabledMoveUp ? 'gray' : '', cursor: disabledMoveUp ? 'not-allowed' : 'pointer' }}>
                  <i className="fas fa-arrow-up" title="Move Up" style={{ fontSize: '15px', color: 'green' }}></i>Up
                </a>)}
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 && (<a onClick={() => moveRoleDown()} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: disabledMoveDown ? 'gray' : '', cursor: disabledMoveDown ? 'not-allowed' : 'pointer' }}>
                  <i className="fas fa-arrow-down" title="Move Down" style={{ fontSize: '15px', color: 'blue' }}></i>Down
                </a>)}
              </td>

              {(allSelectedEffortCell.length > 0 || allNewlyCopiedEffortCell.length > 0 || displayMultiCellCutButton
                || displayMultiCellClearButton || displayMultiCellPasteButton || displayMultiCellUndoButton)
                && <td>
                  <table>
                    <tr style={{ backgroundColor: 'white', border: '3px solid black' }}>
                      {selectedCellSummary && <td>
                        <strong style={{ paddingRight: '2px' }}>Sum:</strong>{selectedCellSummary.Sum?.toFixed(2)},<strong> Count:</strong> {formatToUSCurrency(selectedCellSummary.TotalCost)} | {selectedCellSummary.ValueCell}/{selectedCellSummary.TotalCell}
                      </td>}
                      {displayMultiCellCopyButton && <td>
                        <a onClick={() => copySelectedCellHours()}
                          title="Ctrl+c"
                          style={{ textDecoration: 'none', paddingLeft: '2px', paddingBottom: '5px', color: 'black', cursor: 'pointer' }}>
                          <i className="fas fa-copy" title="Copy Efforts" style={{ paddingRight: '5px' }}></i>Copy
                        </a>
                      </td>
                      }
                      {displayMultiCellCutButton && <td>
                        <a onClick={() => cutSelectedCellHours()}
                          title="Ctrl+x"
                          style={{ textDecoration: 'none', paddingLeft: '2px', paddingBottom: '5px', color: 'black', cursor: 'pointer' }}>
                          <i className="fas fa-cut" title="Cut selected efforts" style={{ paddingRight: '5px' }}></i>Cut
                        </a>
                      </td>
                      }
                      {displayMultiCellPasteButton && <td>
                        <a onClick={() => pasteSelectedCellHours()}
                          title="Ctrl+v"
                          style={{ textDecoration: 'none', paddingLeft: '2px', paddingBottom: '5px', color: 'black', cursor: 'pointer' }}>
                          <i className="fas fa-paste" title="Paste copied efforts" style={{ paddingRight: '5px' }}></i>Paste
                        </a>
                      </td>
                      }
                      {displayMultiCellUndoButton && <td>
                        <a onClick={() => undoSelectedCellHours()}
                          title="Ctrl+z"
                          style={{ textDecoration: 'none', paddingLeft: '2px', paddingBottom: '5px', color: 'black', cursor: 'pointer' }}>
                          <i className="fas fa-undo" title="Undo only last copied efforts" style={{ paddingRight: '5px' }}></i>Undo
                        </a>
                      </td>
                      }
                      {displayMultiCellClearButton && <td>
                        <a onClick={() => clearSelectedCellHours()}
                          title="Ctrl+Del"
                          style={{ textDecoration: 'none', paddingLeft: '2px', paddingBottom: '5px', color: 'black', cursor: 'pointer' }}>
                          <i className="fas fa-eraser" title="Copy Efforts" style={{ paddingRight: '5px' }}></i>Clear
                        </a>
                      </td>
                      }
                    </tr>
                  </table>
                </td>
              }
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={() => saveAllWeeklyEffortsEvent()}
                    title="keyboard Ctrl+s"
                    className={`w-100 ${saveEffortDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: saveEffortDisabled ? 'gray' : '', cursor: saveEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i style={{ visibility: isSaveEffortButtonVisible ? 'visible' : 'hidden', color: saveEffortButtonColor, fontSize: saveEffortButtonColor ? '15pt' : '' }} className="fas fa-save"></i> {autoSaveText ?? saveButtonText}
                  </a>
                }
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={() => deleteEffortEstimation()}
                    title="Select all row to delete efforts"
                    className={`w-100 ${deleteEstimationDisabled ? 'disabled-link' : ''}`}
                    style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: deleteEstimationDisabled ? 'gray' : '', cursor: deleteEstimationDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-trash text-danger"></i> Delete
                  </a>
                }
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={resetAllWeeklyEfforts}
                    title="It will will show last database saved data"
                    className={`w-100 ${resetEffortDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: resetEffortDisabled ? 'gray' : '', cursor: resetEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-undo"></i> Reset
                  </a>}
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={downloadEfforts} className={`w-100 ${resetEffortDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '20px', paddingBottom: '5px', color: addEffortDisabled ? 'gray' : '', cursor: addEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-download"></i> Download
                  </a>}
              </td>
              {/* <td className="align-middle">
              {allRoleResourseEstimation.length > 0 && <Form.Check
                style={{ marginTop: '0px', marginBottom: '0px', cursor: 'pointer', height: '24px' }}
                type="checkbox"
                disabled={disabledHideOrReadonly}
                label="Exclude Blank"
                name="ExcludeBlankWeek"
                checked={excludeBlankWeek}
                onChange={(e) => setExcludeBlankWeek(!excludeBlankWeek)}
              >
              </Form.Check>
              }
            </td> */}
              <td className="align-middle">
                {allRoleResourseEstimation.length > 0 && <Form.Check
                  style={{ marginTop: '0px', marginBottom: '0px', cursor: 'pointer', height: '24px' }}
                  type="switch"
                  id="autosave-efforts"
                  label="Auto Save"
                  title="Auto Save On/Off"
                  checked={autoSaveEnable}
                  onChange={() => setAutoSaveEnable(!autoSaveEnable)}
                />
                }
              </td>
              <td className="align-middle">
                {allRoleResourseEstimation.length > 0 && <Form.Check
                  style={{ marginTop: '0px', marginBottom: '0px', cursor: 'pointer', height: '24px' }}
                  type="switch"
                  id="resource-estimate-phase-color"
                  label="Phase Color"
                  title="Phase Color"
                  checked={resourcePhaseColor}
                  onChange={() => setResourcePhaseColor(!resourcePhaseColor)}
                />
                }
              </td>
              <td>
                {allRoleResourseEstimation.length > 0 &&
                  <a onClick={(e) => addWeekEffort(false)}
                    title="Ctrl+i"
                    className={`w-100 ${addEffortDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '1px', paddingBottom: '5px', color: addEffortDisabled ? 'gray' : '', cursor: addEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-calendar-week"></i> Add Week
                  </a>
                }
              </td>
            </tr>
          </table>
        </div>
      </div>
      }
      <div
        ref={tableResourceEffortRef}
        style={{ maxHeight: '670px', overflow: 'scroll', minHeight: '400px', border: '1px solid grey' }}
        onScroll={handleResourceEffortsScroll}
      >
        <table
          className="scrolling-table">
          <thead style={{ position: 'sticky', top: '0' }}>
            <tr>
              <th onClick={unselectAllSelectedEstimationRow} style={{ backgroundColor: '#0018D4', textAlign: 'center', borderCollapse: 'collapse', color: 'white', fontSize: '15px', borderLeft: '0px solid #ccc' }}>
                <strong style={{ color: 'red' }}>{allRoleResourseEstimation.length > 0 && selectedResourceEstimationRows.length > 0 && `${selectedResourceEstimationRows.length}`}</strong>
              </th>
              <th style={{ backgroundColor: '#0018D4', borderCollapse: 'collapse', color: 'white', fontSize: '15px', borderLeft: '0px solid #ccc' }}>Phase/Deliverable</th>
              <th style={{ backgroundColor: '#0018D4', borderCollapse: 'collapse', color: 'white', fontSize: '15px', borderLeft: '0px solid #ccc' }}>Task</th>
              <th style={{ backgroundColor: '#0018D4', color: 'white', fontSize: '15px', borderLeft: '0px solid #ccc' }}>Role</th>
              <th style={{ backgroundColor: '#0018D4', color: 'white', fontSize: '15px', textAlign: 'center' }}>Rate</th>
              <th style={{ backgroundColor: '#0018D4', color: 'white', fontSize: '15px', textAlign: 'center' }}>Hours</th>
              <th style={{ backgroundColor: '#0018D4', color: 'white', fontSize: '15px', textAlign: 'center' }}>Fees</th>
              {weekEffortColumn.map((column) => {

                let [weekStartDate, weekEndDate] = getWeekDateRangeByWeekNumber((currentProjectInfoGblCtx?.StartDate ?? undefined), column);
                let startDateStrHi = weekStartDate.toISOString().substring(0, 10);
                let startDateStrSl = formatDateAsString(weekStartDate);
                if (maxWeekIndex == column && currentProjectInfoGblCtx?.EndDate && (weekStartDate < currentProjectInfoGblCtx?.EndDate)) {
                  weekEndDate = currentProjectInfoGblCtx?.EndDate;
                }
                let endDateStr = formatDateAsString(weekEndDate);

                return (<th key={column} onClick={(e) => weekNumberColumnClicked(column)}
                  className="text-center" style={{ paddingTop: '0px', backgroundColor: '#0078D4', color: 'white', cursor: 'pointer' }}>
                  <div title={`${startDateStrSl}-${endDateStr}`} style={{ alignItems: 'center' }}>
                    {<p style={{ fontSize: '12px', marginTop: '0', marginBottom: '0.1rem' }}>{column}
                      {!disabledHideOrReadonly && column == weekEffortColumn.length && column > 1 ? (
                        <a style={{ marginLeft: '5px', cursor: 'pointer' }}
                          title="Ctrl+i"
                          onClick={() => deleteWeeklyColumn(column)} className="">
                          <i className="fas fa-trash text-danger"></i>
                        </a>
                      ) : ''}
                    </p>
                    }
                    <p style={{ fontSize: '10px', marginTop: '0', marginBottom: '0.1rem' }}>{startDateStrHi}</p>
                  </div>
                </th>);
              })
              }
              <th style={{ backgroundColor: '#0078D4', width: '100%' }}>
                {!disabledHideOrReadonly && !addEffortDisabled && allRoleResourseEstimation.length > 0 &&
                  <a onClick={(e) => addWeekEffort(true)} className={`w-100 ${addEffortDisabled ? 'disabled-link' : ''}`} style={{ textDecoration: 'none', paddingLeft: '1px', paddingRight: '20px', paddingBottom: '5px', color: addEffortDisabled ? 'gray' : '', cursor: addEffortDisabled ? 'not-allowed' : 'pointer' }}>
                    <i className="fas fa-plus" style={{ color: 'white' }}></i>
                  </a>
                }
              </th>
            </tr>
          </thead>
          <tbody>
            {loadingIconEffortGrid && <LoadingIcon />}
            {allRoleResourseEstimation.length > 0 && allRoleResourseEstimation.map((resourceEstimation, rowIndex) => {
              if (!performanceTuningOn || (rowIndex >= visibleResourceEffortRange.minResourceRow && rowIndex <= (visibleResourceEffortRange.maxResourceRow))) {
                let roleControlName = `Role${resourceEstimation.RoleResourceEstimateId}`
                let roleDrpFontColor = resourceEstimation.PrjectRoleResourceDetail?.RoleResourceId != undefined && resourceEstimation.PrjectRoleResourceDetail?.RoleResourceId > 0 ? 'black' : 'red';
                let isRowSeleted = selectedResourceEstimationRows.filter((res) => res.RoleResourceEstimateId == resourceEstimation.RoleResourceEstimateId)?.length > 0;
                let appearanceText = (resourceEstimation.PrjectRoleResourceDetail?.AppearanceOrder ? `(${resourceEstimation.PrjectRoleResourceDetail?.AppearanceOrder})` : '') + (resourceEstimation.PrjectRoleResourceDetail?.Resource ? `-${resourceEstimation.PrjectRoleResourceDetail?.Resource}` : '')
                let phaseCellColor = '';
                if (resourcePhaseColor && resourceEstimation.Phase?.trim()?.length > 0) {
                  let phaseColorIndex = phaseEffort.getAllPhase(allRoleResourseEstimation)?.findIndex(p => p?.trim()?.toLowerCase() == resourceEstimation.Phase?.trim()?.toLowerCase());
                  phaseCellColor = getRGBColors(phaseColorIndex);
                }
                if (isRowSeleted) {
                  phaseCellColor = rowOrCloumnSelectedColor;
                }

                return (
                  <tr key={rowIndex}>
                    <td onClick={() => hadleRoleResourceEstimationRowClick(rowIndex, resourceEstimation)} style={{ marginTop: '10px', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '#118AD8' }}>
                      <div className="d-flex">
                        <div style={{ marginRight: '2px', fontWeight: 'bold', color: 'black' }}>{rowIndex + 1}</div>
                        {!disabledHideOrReadonly && <div style={{ width: '1px' }}>
                          <a onClick={() => deleteRoleResourceEstimation(rowIndex, resourceEstimation)} style={{ textDecoration: 'none', paddingLeft: '0px', paddingBottom: '0px', color: deleteRoleDisabled ? 'gray' : '', cursor: deleteRoleDisabled ? 'not-allowed' : 'pointer' }}>
                            <i className="fas fa-trash" title="Delete Effort" style={{ fontSize: '10px', color: 'red', cursor: 'pointer' }}></i>
                          </a>
                        </div>
                        }
                      </div>
                    </td>
                    <td className={isPhaseRowSelectedColorRequired(resourceEstimation.RoleResourceEstimateId, phaseColIndex) ? "bg-success" : ''}
                      style={{ backgroundColor: resourceEstimation.TotalHours > 0 ? phaseCellColor : '' }}
                      onMouseEnter={() => handlePhaseMouseMove(resourceEstimation.RoleResourceEstimateId, rowIndex, phaseColIndex)}
                      onMouseDown={() => handlePhaseMouseDown(resourceEstimation.RoleResourceEstimateId, rowIndex, phaseColIndex)}
                      onMouseUp={() => handlePhaseMouseUp(resourceEstimation.RoleResourceEstimateId, rowIndex, phaseColIndex)}
                    >
                      <Form.Control
                        type="textarea"
                        disabled={disabledHideOrReadonly}
                        name={`Phase${resourceEstimation.RoleResourceEstimateId}`}
                        className="form-control fw-bold"
                        placeholder={`Enter Phase`}
                        title={resourceEstimation.Phase ?? ''}
                        value={resourceEstimation.Phase ?? ''}
                        onKeyDown={(e: any) => handlePhaseKeyDown(e, resourceEstimation, (rowIndex * totalPhaseCol + phaseColIndex - 1), rowIndex, phaseColIndex)}
                        onChange={(e) => handleInputChange(e, resourceEstimation, rowIndex, phaseColIndex)}
                        ref={(el: any) => {
                          phaseTextBoxRefs.current[rowIndex * totalPhaseCol + phaseColIndex - 1] = el;
                        }}
                        style={{ height: `${cellHeight}px`, fontSize: '8pt', width: '128px', marginLeft: '0px', backgroundColor: resourceEstimation.TotalHours > 0 ? phaseCellColor : '', borderColor: phaseCellColor }}
                      />
                    </td>
                    <td className={isPhaseRowSelectedColorRequired(resourceEstimation.RoleResourceEstimateId, subPhaseColIndex) ? "bg-success" : ''}
                      style={{ borderColor: '' }}
                      onMouseEnter={() => handlePhaseMouseMove(resourceEstimation.RoleResourceEstimateId, rowIndex, subPhaseColIndex)}
                      onMouseDown={() => handlePhaseMouseDown(resourceEstimation.RoleResourceEstimateId, rowIndex, subPhaseColIndex)}
                      onMouseUp={() => handlePhaseMouseUp(resourceEstimation.RoleResourceEstimateId, rowIndex, subPhaseColIndex)}
                    >
                      <Form.Control
                        type="textarea"
                        name={`Task${resourceEstimation.RoleResourceEstimateId}`}
                        disabled={disabledHideOrReadonly}
                        className="form-control fw-bold"
                        placeholder={`Enter Task`}
                        title={resourceEstimation.SubPhase ?? ''}
                        value={resourceEstimation.SubPhase ?? ''}
                        onKeyDown={(e: any) => handlePhaseKeyDown(e, resourceEstimation, (rowIndex * totalPhaseCol + subPhaseColIndex - 1), rowIndex, subPhaseColIndex)}
                        onChange={(e) => handleInputChange(e, resourceEstimation, rowIndex, subPhaseColIndex)}
                        ref={(el: any) => {
                          phaseTextBoxRefs.current[rowIndex * totalPhaseCol + subPhaseColIndex - 1] = el;
                        }}
                        style={{ height: `${cellHeight}px`, fontSize: '8pt', marginLeft: '0px', borderColor: '', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '' }}
                      />
                    </td>
                    <td className="text-wrap" style={{ textAlign: 'left', borderLeft: '1px solid #ccc', borderColor: '' }}>
                      {loadingIconRole && loadingIconRoleId == resourceEstimation?.RoleResourceEstimateId ? <LoadingIcon /> :
                        (<Form.Control as="select"
                          name={roleControlName}
                          className="fw-bold"
                          isInvalid={!!validationErrors[roleControlName]}
                          disabled={disabledHideOrReadonly}
                          title={`${resourceEstimation.PrjectRoleResourceDetail?.PCRRoleRate?.Role.Name ?? ''}${appearanceText}`}
                          value={resourceEstimation.ProjectRoleResourceId ?? -1}
                          onChange={(e) => handleAndSaveRoleResourceChange(e, rowIndex, resourceEstimation)}
                          style={{ height: `${cellHeight}px`, width: '143', fontSize: '10pt', color: roleDrpFontColor, fontWeight: 'bold', borderColor: isRowSeleted ? rowOrCloumnSelectedColor : '', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '' }}
                        >
                          <option style={{ fontSize: '11pt' }} value="-1" disabled>Select a Role</option>
                          {
                            allProjectRoleResoursesGblCtx?.map((resourceRole, index) => {
                              let appearanceText = (resourceRole.AppearanceOrder ? `(${resourceRole.AppearanceOrder})` : '') + (resourceRole.Resource ? `-${resourceRole.Resource}` : '')
                              return (
                                <option style={{ fontSize: '11pt' }} key={index} value={resourceRole.RoleResourceId} title={`${resourceRole.PCRRoleRate?.Role.Name} | ${resourceRole.Resource} | ${resourceRole.PCRRoleRate?.Role.Description}`}>
                                  {resourceRole.PCRRoleRate?.Role.Name && (resourceRole.PCRRoleRate?.Role.Name?.length > 19 || resourceRole.Resource?.length > 0) ? `${resourceRole.PCRRoleRate?.Role.Code}${appearanceText}` : `${resourceRole.PCRRoleRate?.Role.Name}${appearanceText}`}
                                </option>
                              )
                            })
                          }
                        </Form.Control>)
                      }
                    </td>
                    <td onClick={() => hadleRoleResourceEstimationRowClick(rowIndex, resourceEstimation)}
                      title={`STD Rate: ${formatToUSCurrency(resourceEstimation.PrjectRoleResourceDetail?.STDRate)}`}
                      style={{ textAlign: 'center', fontWeight: 'bold', borderColor: '', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '' }}>
                      {formatToUSCurrency(resourceEstimation.PrjectRoleResourceDetail?.OfferedRate ?? 0)}
                      {resourceEstimation.PrjectRoleResourceDetail?.IsSubContractor && resourceEstimation.PrjectRoleResourceDetail.SubContractorFees && resourceEstimation.PrjectRoleResourceDetail.SubContractorFees > 0 && (<div style={{ color: 'brown' }}>{formatToUSCurrency(resourceEstimation.PrjectRoleResourceDetail.SubContractorFees)}</div>)}
                    </td>
                    <td onClick={() => hadleRoleResourceEstimationRowClick(rowIndex, resourceEstimation)} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '' }}>
                      {resourceEstimation.TotalHours?.toFixed(2)}
                    </td>
                    <td onClick={() => hadleRoleResourceEstimationRowClick(rowIndex, resourceEstimation)} style={{ textAlign: 'right', fontWeight: 'bold', borderRight: '1px solid #445511', backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '#d3ddee' }}>
                      {formatToUSCurrency(resourceEstimation.TotalFees)}
                      {resourceEstimation.PrjectRoleResourceDetail?.IsSubContractor && resourceEstimation.TotalSubContractorFees && resourceEstimation.TotalSubContractorFees > 0 && (<div style={{ color: 'brown' }}>{formatToUSCurrency(resourceEstimation.TotalSubContractorFees)}</div>)}
                    </td>
                    {
                      weekEffortColumn.map((column, colIndex) => {
                        let effortHours = allTempEffortHours.filter(item => item.WeekNumber == column && item.RoleResourceEstimateId == resourceEstimation.RoleResourceEstimateId)[0];
                        if (effortHours == undefined) {
                          effortHours = getProjectEffortHours(resourceEstimation.RoleResourceEstimateId, 0, column, 0);
                        }
                        let controlName = `R${resourceEstimation.RoleResourceEstimateId}WI${effortHours.WeekId}WN${effortHours.WeekNumber}`;
                        let isRowSelectColor = isEffortRowSelectedColorRequired(resourceEstimation.RoleResourceEstimateId, effortHours.WeekId, effortHours.WeekNumber)
                        const effortInputIndex = rowIndex * maxWeekIndex + colIndex;
                        let fontClass = effortHours.EffortHours > 0 ? 'fw-bold' : '';
                        let effortCellBorderColor = isRowSelectColor && clickedMultiCellCutButton ? 'bg-danger' : isRowSelectColor && !clickedMultiCellCutButton ? "bg-success" : ''

                        let isNewlyCopied = allNewlyCopiedEffortCell?.some(item => item.RoleResourceEstimateId == resourceEstimation.RoleResourceEstimateId && item.WeekNumber == effortHours.WeekNumber)
                        effortCellBorderColor = isNewlyCopied == true ? 'bg-danger' : effortCellBorderColor

                        let finalBorderColor = (isRowSelectColor == true || isNewlyCopied == true) ? effortCellBorderColor : phaseCellColor;
                        let finalEffortBackGroundColor = effortHours.EffortHours > 0 ? phaseCellColor : '';
                        if (selectedWeekNumberColumn && selectedWeekNumberColumn > 0 && column == selectedWeekNumberColumn) {
                          finalBorderColor = rowOrCloumnSelectedColor;
                          finalEffortBackGroundColor = rowOrCloumnSelectedColor
                        } else if (isRowSeleted) {
                          finalBorderColor = rowOrCloumnSelectedColor;
                          finalEffortBackGroundColor = rowOrCloumnSelectedColor
                        }
                        if (!performanceTuningOn || (colIndex >= (visibleResourceEffortRange.minEffortCol) && colIndex <= (visibleResourceEffortRange.maxEffortCol))) {
                          return (
                            <td key={`cell-${rowIndex}-${colIndex}`} align="center"
                              className={effortCellBorderColor}
                              style={{ width: `${cellWidth}px`, borderColor: finalBorderColor, backgroundColor: finalEffortBackGroundColor }}
                              onClick={(e: any) => handleEffortCellClicked(e, rowIndex, column, resourceEstimation.RoleResourceEstimateId, effortHours.WeekId, false)}
                              onDoubleClick={(e: any) => handleEffortCellClicked(e, rowIndex, column, resourceEstimation.RoleResourceEstimateId, effortHours.WeekId, true)}
                              onMouseEnter={() => handleEffortMouseMove(rowIndex, column, resourceEstimation.RoleResourceEstimateId, effortHours.WeekId)}
                              onMouseDown={(e: any) => handleEffortMouseDown(e, rowIndex, column, resourceEstimation.RoleResourceEstimateId, effortHours.WeekId)}
                              onMouseUp={() => handleEffortMouseUp(rowIndex, column, resourceEstimation.RoleResourceEstimateId, effortHours.WeekId)}
                            >
                              <Form.Control
                                type="number"
                                min={0}
                                max={55}
                                disabled={disabledHideOrReadonly}
                                name={controlName}
                                className={"form-control no-spin-buttons " + fontClass}
                                placeholder={``}
                                autoComplete="off"
                                value={effortHours.EffortHours > 0 ? effortHours.EffortHours.toString() : ''}
                                style={{
                                  cursor: effortCursor,
                                  width: `${cellWidth}px`,
                                  height: `${cellHeight}px`,
                                  fontSize: '9pt',
                                  borderColor: finalEffortBackGroundColor,
                                  marginLeft: '0px',
                                  backgroundColor: finalEffortBackGroundColor
                                }}
                                isInvalid={!!validationErrors[controlName]}
                                ref={(el: any) => {
                                  effortsTextBoxRefs.current[effortInputIndex] = el;
                                }}
                                onWheel={(e) => e.currentTarget.blur()}
                                onPaste={handleEffortPaste}
                                onKeyDown={(e: any) => handleEffortKeyDown(e, effortInputIndex, resourceEstimation.RoleResourceEstimateId, rowIndex, column)}
                                onChange={(e: any) => handleWeekEffortChange(e, resourceEstimation.RoleResourceEstimateId, rowIndex, effortHours.WeekNumber, effortHours.WeekId)}
                              />
                            </td>
                          )
                        }
                        else {
                          return (
                            <td key={`cell-${rowIndex}-${colIndex}`} align="center"
                              className={effortCellBorderColor}
                              style={{ borderColor: finalBorderColor, backgroundColor: finalEffortBackGroundColor }}
                            >
                            </td>
                          )
                        }
                      }
                      )}
                    <td onClick={() => hadleRoleResourceEstimationRowClick(rowIndex, resourceEstimation)} style={{ backgroundColor: isRowSeleted ? rowOrCloumnSelectedColor : '' }}>
                    </td>
                  </tr>
                )
              }
              else {
                return (
                  <tr style={{ height: `${cellHeight}px` }}>
                    <td></td>
                  </tr>
                )
              }
            })}
          </tbody>
          {allRoleResourseEstimation.length > 0 &&
            (<tfoot>
              <tr>
                <td style={{ textAlign: 'right', backgroundColor: 'white', borderBottomColor: 'white' }}></td>
                <td style={{ textAlign: 'right', backgroundColor: 'white', borderBottomColor: 'white' }}></td>
                <td style={{ textAlign: 'right', backgroundColor: 'white', borderBottomColor: 'white' }}></td>
                <td style={{ textAlign: 'right', backgroundColor: 'white', borderBottomColor: 'white' }}></td>
                <td style={{ textAlign: 'right', backgroundColor: 'white', borderBottomColor: 'white', fontSize: '10pt', fontWeight: 'bold' }}>Total=</td>
                <td style={{ textAlign: 'center', backgroundColor: 'green', color: 'white', fontSize: '15px', borderBottomColor: 'white' }}>{projectEffortSummaryGblCtx.TotalHours?.toFixed(2)}</td>
                <td style={{ textAlign: 'right', backgroundColor: 'green', color: 'white', fontSize: '15px', borderBottomColor: 'white' }}>{formatToUSCurrency(projectEffortSummaryGblCtx.ProjectCost)}</td>
              </tr>
            </tfoot>)}
        </table>
        {!loadingIconEffortGrid && allRoleResourseEstimation.length == 0 && (
          <div style={{ paddingTop: 'center', textAlign: 'center' }}>No record found.</div>
        )}
      </div>
    </>
  );
};

export default ProjectEffortDetails;
